import FormInput from "@/components/form/FormInput";
import FormSelect from "@/components/form/FormSelect";
import FormTextarea from "@/components/form/FormTextarea";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} from "../api/payment-type-command";
import { IncomeExepense, IPaymentType } from "../models/payments";
import { useEffect } from "react";
import CustomButton from "@/components/shared/Button/CustomButton";
import FormCheckboxWithSwitch from "@/components/form/FormCheckboxWithSwitch";
import { NotificationOptions } from "@/model/enums";
import FormMultiSelect from "@/components/form/FormMultiSelect";
import { Form } from "@/components/ui/form";

type Props = {
  open: boolean;
  onClose: () => void;
  selectedPaymentType: IPaymentType | null;
  isUpdating: boolean;
};
const schema = z.object({
  name: z.string(),
  remark: z.string().nonempty("Remark is Required!"),
  isActive: z.boolean().default(true),
  incomeExepense: z.enum(["Income", "Expense"]),
  notificationOption: z.array(z.nativeEnum(NotificationOptions)).optional(),
});

type FormValues = z.infer<typeof schema>;

export const AddEditPaymentTypeDialog = ({
  open,
  onClose,
  selectedPaymentType,
  isUpdating,
}: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: selectedPaymentType?.name ?? "",
      remark: selectedPaymentType ? selectedPaymentType.remark : "",
      isActive: selectedPaymentType?.isActive ?? true,
      incomeExepense: selectedPaymentType?.incomeExepense ?? "Income",
      notificationOption: selectedPaymentType?.notificationOption
        ? selectedPaymentType?.notificationOption
        : [],
    },
  });
  const [
    createPaymentMethod,
    {
      isLoading: isCreatingPaymentMethodLoading,
      isSuccess: isCreatingPaymentMethodSuccess,
    },
  ] = useCreatePaymentMethodMutation();

  const [
    updatePaymentMethod,
    {
      isLoading: isUpdatingPaymentMethodLoading,
      isSuccess: isUpdatingPaymentMethodSuccess,
    },
  ] = useUpdatePaymentMethodMutation();

  const onSubmit = async (data: FormValues) => {
    if (isUpdating) {
      if (selectedPaymentType)
        await updatePaymentMethod({
          data: {
            ...selectedPaymentType,
            name: data.name,
            remark: data.remark,
            isActive: data.isActive,
            incomeExepense: data.incomeExepense as IncomeExepense,
            notificationOption: data.notificationOption ?? [],
          },
        });
      return;
    }
    await createPaymentMethod({
      data: {
        name: data.name,
        remark: data.remark,
        isActive: true,
        incomeExepense: data.incomeExepense as IncomeExepense,
        notificationOption: data.notificationOption ?? [],
      },
    });
  };
  useEffect(() => {
    if (isCreatingPaymentMethodSuccess || isUpdatingPaymentMethodSuccess) {
      onClose();
    }
  }, [isCreatingPaymentMethodSuccess, isUpdatingPaymentMethodSuccess, onClose]);

  return (
    <DefaultDialog open={open} onClose={onClose} title="Add/Edit Payment Type">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormInput control={form.control} name="name" label="Name" required />
          <FormTextarea
            control={form.control}
            name="remark"
            label="Remark"
            required={true}
          />
          <FormSelect
            control={form.control}
            name="incomeExepense"
            label="Income/Expense"
            required={true}
            options={[
              { label: "Income", value: "Income" },
              { label: "Expense", value: "Expense" },
            ]}
          />
          {isUpdating && (
            <FormCheckboxWithSwitch
              control={form.control}
              name="isActive"
              label="Is Active"
            />
          )}
          <FormMultiSelect
            form={form}
            control={form.control}
            formLabel="Notification Options"
            name="notificationOption"
            options={Object.entries(NotificationOptions)
              .filter(([key]) => key !== "None" && key !== "All")
              .map(([key]) => ({
                label: key,
                value:
                  NotificationOptions[key as keyof typeof NotificationOptions],
              }))}
          />
          <div className="flex justify-center items-center mt-2 w-full">
            <CustomButton
              type="submit"
              isLoading={
                isCreatingPaymentMethodLoading || isUpdatingPaymentMethodLoading
              }
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
