import { DefaultPage } from "@/components/shared/DefaultPage";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { AddGenericTaskDialog } from "../components/AddGenericTask";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { GenericTaskListByStatus } from "../components/GenericTaskListByStatus";

const GenericTask = () => {
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("non-started");
  return (
    <>
      <div>
        <DefaultPage
          headerTitle="Generic Task"
          headerActionButton={
            <Button onClick={() => setOpenAddForm(true)}>Add New Task</Button>
          }
        >
          <Tabs
            defaultValue={selectedTab}
            className=""
            onValueChange={setSelectedTab}
          >
            <TabsList className="flex justify-start w-full gap-2">
              <TabsTrigger value="non-started">Non Started</TabsTrigger>
              <TabsTrigger value="ongoing">On Going</TabsTrigger>
              <TabsTrigger value="completed" className="text-green-700">
                Completed
              </TabsTrigger>
              <TabsTrigger value="Cancelled" className="text-red-300">
                Cancelled
              </TabsTrigger>
              <TabsTrigger value="Suspended" className="text-yellow-500">
                Suspended
              </TabsTrigger>
            </TabsList>
            <TabsContent value="ongoing">
              <GenericTaskListByStatus status="ongoing" />
            </TabsContent>
            <TabsContent value="non-started">
              <GenericTaskListByStatus status="Submitted" />
            </TabsContent>
            <TabsContent value="completed">
              <GenericTaskListByStatus status="Finished" />
            </TabsContent>
            <TabsContent value="Cancelled">
              <GenericTaskListByStatus status="Canceled" />
            </TabsContent>
            <TabsContent value="Suspended">
              <GenericTaskListByStatus status="Suspended" />
            </TabsContent>
          </Tabs>
        </DefaultPage>
      </div>
      {openAddForm && (
        <AddGenericTaskDialog
          openDialog={openAddForm}
          setOpenDialog={setOpenAddForm}
        />
      )}
    </>
  );
};

export default GenericTask;
