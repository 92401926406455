import React from "react";

interface CustomListProps {
  children: React.ReactNode;
}

const CustomList = ({ children }: CustomListProps): JSX.Element => {
  return <ul className="divide-y divide-gray-200 border">{children}</ul>;
};

interface CustomListItemProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const CustomListItem = ({
  children,
  className = "",
  onClick,
}: CustomListItemProps): JSX.Element => {
  return (
    <li
      onClick={onClick}
      className={`p-4 cursor-pointer ${className}`}
    >
      {children}
    </li>
  );
};

export { CustomList, CustomListItem };
