import { useEffect, useState } from "react";
import { CalendarIcon, CircleDashed } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn, formatToETB, toTitleCase } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import DashboardCard from "@/features/dashboard/client/components/DashboarCard";
import { useGetFinanceDashboardQuery } from "../api/api";
import { ExpenseKeys, RevenueKeys } from "../model/dashboard";


const FinanceDashboard = () => {
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();

  const { data: financeDashboardReport, refetch } = useGetFinanceDashboardQuery({
    data: {
      startDate: fromDate,
      endDate: toDate,
    },
  });

  useEffect(() => {
    if (toDate || fromDate) {
      refetch();
    }
  }, [fromDate, toDate]);

  return (
    <div className="py-4 px-2 md:px-6 2xl:px-11 flex flex-col gap-4">
      <div className="flex justify-end gap-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] justify-start text-left font-normal",
                !fromDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {fromDate ? format(fromDate, "PPP") : <span>From date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar mode="single" selected={fromDate} onSelect={setFromDate} initialFocus />
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] justify-start text-left font-normal",
                !toDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {toDate ? format(toDate, "PPP") : <span>To date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar mode="single" selected={toDate} onSelect={setToDate} initialFocus />
          </PopoverContent>
        </Popover>
      </div>

      {/* Pipeline Section */}
      <div className="p-4 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">Revenue</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {financeDashboardReport &&
        (Object.keys(financeDashboardReport?.revenue) as RevenueKeys[]).map((key) => (
          <DashboardCard
            key={key}
            title={toTitleCase(key)}
            count={formatToETB(financeDashboardReport?.revenue[key])} // Access is now safe
            icon={<CircleDashed />}
            link="/pipeline/details"
            linkText="View Details"
            bgColor="white"
            hideAction={true}
          />
        ))}
        </div>
      </div>

      {/* Tender Section */}
      <div className="p-4 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">Expense</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {financeDashboardReport &&
        (Object.keys(financeDashboardReport?.expense) as ExpenseKeys[]).map((key) => (
          <DashboardCard
            key={key}
            title={toTitleCase(key)}
            count={formatToETB(financeDashboardReport?.expense[key])} // Access is now safe
            icon={<CircleDashed />}
            link="/pipeline/details"
            linkText="View Details"
            bgColor="white"
            hideAction={true}
          />
        ))}
        </div>
      </div>
    </div>
  );
};

export default FinanceDashboard;