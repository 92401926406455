export enum ExpenseTo {
    Task = "Task",
    GenericTask = "GenericTask",
    Direct = "Direct",
    SalesTask = "SalesTask",
}
  
export interface Expense {
    id: string;
    payTo?: string;
    clientId?: string;
    amount: number;
    createdById: string;
    date: string; // ISO format (e.g., "YYYY-MM-DD")
    dueDate: string; // ISO format (e.g., "YYYY-MM-DD")
    taskId?: string;
    expenseTo: ExpenseTo;
    remark?: string;
    paymentTypeId?: string;
    organizationId?: string;
    status: string,
    isPaid: boolean
}
  