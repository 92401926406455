// src/components/VoidExpense.js
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import React, { SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { useVoidExpenseMutation } from "../api/expense-api"; // Assuming you have a mutation for voiding expenses
import { Expense } from "../model/expense";

// Define Zod schema for form validation
const voidExpenseSchema = z.object({
    remark: z.string().nonempty("Remark is required"),
});

type VoidExpenseFormValues = z.infer<typeof voidExpenseSchema>;

interface VoidExpenseProps {
    openForm: boolean;
    setOpenForm: React.Dispatch<SetStateAction<boolean>>;
    expense: Expense
}

const VoidExpense = ({ openForm, setOpenForm, expense }: VoidExpenseProps) => {
    const [voidExpense, { isLoading, isSuccess }] = useVoidExpenseMutation(); // Hook for voiding expense
    const form = useForm<VoidExpenseFormValues>({
        resolver: zodResolver(voidExpenseSchema),
    });

    useEffect(() => {
        if (isSuccess) {
            form.reset();
            setOpenForm(false);
            Notify("success", "Expense voided successfully!");
        }
    }, [isSuccess]);

    const onSubmit = async (data: VoidExpenseFormValues) => {
        await voidExpense({ data: {id: expense.id, remark: data.remark} }); // Call the mutation to void the expense
    };

    return (
        <DefaultDialog
            title="Void Expense"
            open={openForm}
            onClose={() => setOpenForm(false)}
        >
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                {/* Remark Field */}
                <div className="flex flex-col gap-3">
                    <label htmlFor="remark">Remark</label>
                    <Input
                        id="remark"
                        {...form.register("remark")}
                        placeholder="Enter remark"
                    />
                    {form.formState.errors.remark && (
                        <span className="text-red-500">{form.formState.errors.remark.message}</span>
                    )}
                </div>

                {/* Submit Button */}
                <CustomButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    type="submit"
                    className="w-full"
                >
                    Void Expense
                </CustomButton>
            </form>
        </DefaultDialog>
    );
};

export default VoidExpense;