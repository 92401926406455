// components/form/TextInput.tsx
import React from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { SquareArrowOutUpRight } from "lucide-react";

interface TextInputProps {
  label: string;
  value: string | File;
  onChange: (value: File) => void;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
}

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

const FileInput: React.FC<TextInputProps> = ({
  label,
  value,
  onChange,
  disabled,
  required = false,
}) => {
  return (
    <div className="flex flex-col">
      <Label htmlFor="picture" className="flex gap-2">
        {label}
        {value && typeof value === "string" && (
          <div className="mt-2">
            <a
              href={`${BASE_URL}${value}`}
              target="_blank"
              className="text-blue-400 flex gap-1 items-center"
            >
              view
              <SquareArrowOutUpRight size={14} />
            </a>
          </div>
        )}
      </Label>
      <Input
        id="picture"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            onChange(file);
          }
        }}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default FileInput;
