import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import DisplayTab from "./DisplayTab";
import PermissionsTab from "./PermissionsTab";
import Members from "./Members";

interface RoleDetailProps {
  roleId: string;
}

const RoleDetail = ({
roleId
}: RoleDetailProps): JSX.Element => {

  return (
    <div className="py-4">
      {/* <h2 className="text-xl font-semibold mb-4">{role?.roleName}</h2> */}
      <Tabs defaultValue="display">
        <TabsList>
          <TabsTrigger value="display">Display</TabsTrigger>
          <TabsTrigger value="permissions">Permissions</TabsTrigger>
          <TabsTrigger value="members">Manage Members</TabsTrigger>
        </TabsList>

        {/* Display Tab */}
        <TabsContent value="display" className="mt-4">
          <DisplayTab roleId={roleId} />
        </TabsContent>

        {/* Permissions Tab */}
        <TabsContent value="permissions" className="mt-4">
          <PermissionsTab roleId={roleId} />
        </TabsContent>

        {/* Manage Members Tab */}
        <TabsContent value="members" className="mt-4">
          <Members roleId={roleId} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default RoleDetail;
