import { DefaultPage } from "@/components/shared/DefaultPage";
import { useState } from "react";
import { AddCaseDialog } from "./components/AddCaseDialog";
import { useGetCasesQuery } from "./api/case-api";
import TableLoadingSkeleton from "@/components/ui/TableLoadingSkeleton";
import { AddSectorDialog } from "./components/AddSectorDialog";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { CaseList } from "./components/CaseList";

export function Cases() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSectorDialog, setOpenSectorDialog] = useState<boolean>(false);

  const { data: caseList = [], isLoading } = useGetCasesQuery();

  return (
    <DefaultPage
      headerTitle={"Case List"}
      headerActionButton={
          <Button onClick={() => setOpenDialog(true)}>
            <Plus size={18} className="mr-2" />
            New Case
          </Button>
      }
    >
      {openDialog && (
        <AddCaseDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      {openSectorDialog && (
        <AddSectorDialog
          openDialog={openSectorDialog}
          setOpenDialog={setOpenSectorDialog}
        />
      )}
      {isLoading ? <TableLoadingSkeleton /> : <CaseList cases={caseList}/>}
    </DefaultPage>
  );
}
