import { useEffect, useState } from "react";
import { CustomList, CustomListItem } from "@/components/shared/CustomList";
import { Card, CardContent } from "@/components/ui/card";
import { Plus } from "lucide-react";
import { useCreateRoleMutation, useGetRolesQuery } from "../api/user-api";
import RoleDetail from "../componets/RoleDetail";

const RolePage = (): JSX.Element => {
  const { data: roles = [], isLoading } = useGetRolesQuery();
  const [createRole] = useCreateRoleMutation();
  const [activeRoleId, setActiveRoleId] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false); // Track initialization

  const handleRoleSelection = (roleId: string): void => {
    setActiveRoleId(roleId);
  };

  const handleAddRole = async () => {
    // Call the createRole mutation
    const result = await createRole(
      {
        data: {
          role: {
            roleName: "New Role",
            description: "New Role"
          }
        }
      }
    ).unwrap();
    // Set the active role to the newly added role
    setActiveRoleId(result.id);
  };

  // Set the active role to the first item on initial load
  useEffect(() => {
    if (roles.length > 0 && !isInitialized) {
      setActiveRoleId(roles[0].id); // Set to the first role's ID
      setIsInitialized(true); // Mark as initialized
    }
  }, [roles, isInitialized]);

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="border-r border-gray-300 pr-4 w-60">
        {isLoading && <p>Loading roles...</p>}
        <div className="flex justify-between bg-white rounded p-2 py-4">
          <h6 className="mb-2">Roles</h6>
          <Plus onClick={handleAddRole} className="cursor-pointer" />
        </div>
        <CustomList>
          {roles.map((role) => (
            <CustomListItem
              key={role.id}
              onClick={() => handleRoleSelection(role.id)}
              className={`${
                role.id === activeRoleId
                  ? "bg-[#F1F5F9] text-black"
                  : "text-gray-800 bg-white"
              }`}
            >
              <div className="flex justify-between items-center">
                <span className="font-medium">{role.roleName}</span>
              </div>
            </CustomListItem>
          ))}
        </CustomList>
      </div>

      {/* Role Detail Section */}
      <div className="flex-grow pl-4">
        <Card>
          <CardContent>
            {/* Pass active role data to RoleDetail */}
            {roles.length > 0 && activeRoleId && (
              <RoleDetail
                roleId={activeRoleId}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RolePage;
