import { ICustomer } from "@/features/customers/models/Customer";
import { ICase } from "@/features/workflows/Case/models/Case";
import {
  IWorkFlow,
  IWorkFlowWithParameters,
} from "@/features/workflows/Case/models/workflow";
import { ChangeProps } from "@/model/change-props";

export interface ITaskCreate {
  clientId?: string;
  caseTypeId: string;
  startDate: Date;
  endDate?: Date;
  assignedUsersId: string[];
  taskName: string;
  taskDescription?: string;
  ForInternalUse?: boolean;
}

export interface ITask extends ITaskCreate {
  id: string;
  taskReference: string;
  taskStatus: string;
  currentWorkFlowStepId: string;
  taskAssignees: string;
  createUserId: string;
  currentStatus: string;
  completionRate: number;
  taskName: string;
  taskDescription: string;
}

export enum AlertFrom {
  Task = "Task",
  GenericTask = "GenericTask",
  Other = "Other",
  PipelineSalesTask = "PipelineSalesTask",
  TenderSalesTask = "TenderSalesTask",
}
export interface IAlertNotification {
  taskId?: string;
  workFlowId?: string;
  referenceId?: string;
  daysBeforeAlert: number;
  dueDate: Date;
  alertFrom: AlertFrom;
  message: string;
  isNotified?: boolean;
  users?: string[];
  workFlowName: string;
  notifiedOn?: string | null;
  createdBy: string;
  alertTypeId: string;
  alertTypeName?: string;
  isCompleted: boolean;
  id?: string;
  snoozeUnit: string;
  snoozeDuration: number;
  alertTitle: string;
}

export interface ITaskWithClientCase extends ITask {
  client: ICustomer;
  caseType: ICase;
}

export interface ITaskHistory extends ChangeProps {
  taskId: string;
  userName: string;
  oldStatus: string;
  newStatus: string;
  taskDate: Date;
  workFlowStatus: string;
  note: string;
}

export interface ITaskDetail extends ITaskWithClientCase {
  task: ITask;
  client: ICustomer;
  currentWorkFlow: IWorkFlow;
  workFlow: IWorkFlowWithParameters[];
  nextSteps: IWorkFlowWithParameters[];
  caseType: ICase;
}

export interface IRecentTask {
  taskReference: string;
  stratDate: string;
  endDate: string;
  taskStatus: string;
  caseName: string;
  clientName: string;
}

export interface IClientTask {
  id: string;
  taskReference: string;
  startDate: string;
  endDate: string;
  taskStatus: string;
  caseName: string;
  clientName: string;
}

export interface ITaskForClient {
  id: string;
  taskReference: string;
  startDate: string;
  endDate: string;
  taskStatus: string;
  currentWorkflowStep: string;
}

export interface ITaskWithTotalCount {
  task: ITaskWithClientCase[];
  totalCount: number;
}
export interface ITaskNoteCreate {
  taskId: string;
  content: string;
  fileId?: string;
  filePath?: string;
}

export interface ITaskNote extends ITaskNoteCreate {
  id: string;
  userName: string;
  timeStamp: string;
  userId: string;
}

interface InvoiceAttachment {
  id: string;
  clientFinanceId: string;
  clientFinance: string;
  filePath: string;
  uploadedAt: string; // Use Date if you want to work with Date objects instead of strings
}

export interface ITaskPayment extends ChangeProps {
  id: string;
  taskId: string;
  clientId: string;
  paymentDue: number;
  paymentMode: number; // If paymentMode is an enum or specific values, consider using an enum instead of number
  paidAmount: number;
  paymentDeadline: string; // Use Date if you want to work with Date objects instead of strings
  paymentDate: string; // Use Date if you want to work with Date objects instead of strings
  description: string; // Use Date if you want to work with Date objects instead of strings
  invoiceAttachments: InvoiceAttachment[];
  isFixedAmount: boolean;
}
