import { DefaultPage } from "@/components/shared/DefaultPage";
import { useState } from "react";
import { AddSectorDialog } from "./components/AddSectorDialog";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { SectorList } from "./components/SectorList";

export function Sectors() {
  const [openSectorDialog, setOpenSectorDialog] = useState<boolean>(false);

  return (
    <DefaultPage
      headerTitle={"Sectors"}
      headerActionButton={
          <Button onClick={() => setOpenSectorDialog(true)}>
            <Plus size={18} className="mr-2" />
            New Sector
          </Button>
      }
    >
      {openSectorDialog && (
        <AddSectorDialog
          openDialog={openSectorDialog}
          setOpenDialog={setOpenSectorDialog}
        />
      )}
      <SectorList/>
    </DefaultPage>
  );
}