import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Chip,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface MultiSelectInputProps {
  label: string;
  value: string[];
  options: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  required?: boolean;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  label,
  value,
  options,
  onChange,
  disabled,
  required = false,
}) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    onChange(selectedValues);
  };

  return (
    <FormControl
      required={required}
      fullWidth
      margin="normal"
      size="small"
      // variant="filled"
      sx={{
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff",
          border: 1,
          borderColor: "#ced4da",
          borderBottomColor: "#ced4da",
        },
        "& .MuiFilledInput-root:hover": {
          borderColor: "#fff",
        },
        "& .MuiFilledInput-underline:before": {
          borderBottom: "none",
        },
        "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
        "& .MuiFilledInput-underline:after": {
          borderBottom: "none",
        },
      }}
    >
      <Typography variant="caption">{label}</Typography>
      <Select
        multiple
        value={value ?? []}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return label;
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          );
        }}
        disabled={disabled}
        required={required}
        variant="outlined"
        input={<OutlinedInput />}
        inputProps={{ "aria-label": "Without label" }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value ? value.indexOf(option) > -1 : false} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectInput;
