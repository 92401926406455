import { useState } from "react";
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UnCollectedPaymnet } from "../components/UnCollectedPaymnet";
import { CollectedPayment } from "../components/CollectedPayment";
import { VoidedPayment } from "../components/VoidedPayment ";

export const Revenue = () => {
  const [currentTab, setCurrentTab] = useState<string>("Not Collected");

  const handleTabChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <DefaultPageHeader 
      headerTitle="Revenue"
      />
      <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="Not Collected">Not Collected</TabsTrigger>
          <TabsTrigger value="Collected">Collected</TabsTrigger>
          <TabsTrigger value="Voided">Voided</TabsTrigger>
        </TabsList>
        <TabsContent value="Not Collected">
          <UnCollectedPaymnet />
        </TabsContent>
        <TabsContent value="Collected">
          <CollectedPayment/>
        </TabsContent>
        <TabsContent value="Voided">
          <VoidedPayment/>
        </TabsContent>
      </Tabs>
    </>
  );
}; 