import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import dayjs from "dayjs";
import { Expense, ExpenseTo } from "../model/expense";
import { useGetVoidedExpenseQuery } from "../api/expense-api";

const dateOptions = [
  { label: "This Week", value: "This Week" },
  { label: "Last Week", value: "Last Week" },
  { label: "Last 30 Days", value: "Last 30 Days" },
  { label: "Last 6 Months", value: "Last 6 Months" },
  { label: "All", value: "All" },
];

export const VoidedExpense = () => {
  const [selectedDate, setSelectedDate] = useState<string>("All");
  const [selectedExpenseTo, setSelectedExpenseTo] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const { data: expenseList, isLoading: isExpensesFetchLoading, refetch } = useGetVoidedExpenseQuery({
    data: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      expenseTo: selectedExpenseTo,
    },
  });


  const expenseListColumns: MRT_ColumnDef<Expense>[] = [
    {
      accessorKey: "payTo",
      header: "Pay To",
    },
    {
      accessorKey: "amount",
      header: "Amount",
      Cell: ({ row }) => {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "ETB",
        }).format(row.original.amount);
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      Cell: ({ row }) => {
        return new Date(row.original.date).toLocaleDateString();
      },
    },
    {
      accessorKey: "expenseTo",
      header: "Expense To",
    },
    {
      accessorKey: "remark",
      header: "Remark",
    },
  ];

  useEffect(() => {
    let start = null;
    let end = null;
    const today = dayjs();

    switch (selectedDate) {
      case "This Week":
        start = today.startOf("week").add(1, "day").format("YYYY-MM-DD");
        end = today.endOf("week").add(1, "day").format("YYYY-MM-DD");
        break;
      case "Last Week":
        start = today.subtract(7, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 30 Days":
        start = today.subtract(30, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 6 Months":
        start = today.subtract(6, "months").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "All":
        start = null;
        end = null;
        break;
      default:
        break;
    }
    setDateRange({ startDate: start, endDate: end });
  }, [selectedDate]);

  useEffect(() => {
    refetch();
  }, [dateRange, selectedExpenseTo]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex items-center w-full justify-between">
          <div className="justify-self-end flex gap-4">
            <Select onValueChange={setSelectedDate} value={selectedDate}>
              <SelectTrigger className="w-40">
                <SelectValue placeholder="Select Date" />
              </SelectTrigger>
              <SelectContent className="z-9999">
                {dateOptions.map((type, index) => (
                  <SelectItem key={index} value={type.value}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select onValueChange={setSelectedExpenseTo} value={selectedExpenseTo ?? ""}>
              <SelectTrigger className="w-48">
                <SelectValue placeholder="Select Expense To" />
              </SelectTrigger>
              <SelectContent className="z-9999 flex-1">
                {Object.values(ExpenseTo).map((type) => (
                  <SelectItem key={type} value={type}>{type}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <MaterialReactTable
          columns={expenseListColumns}
          data={expenseList ?? []}
          state={{ isLoading: isExpensesFetchLoading }}
          enablePagination
          
        />
      </div>
    </>
  );
};
