import { SetStateAction, useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/shared/PhoneInput/phone-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomButton from "@/components/shared/Button/CustomButton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Notify } from "@/components/shared/Notification/notify";
import { Button } from "@/components/ui/button";
import { PlusCircleIcon, MinusCircleIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateLeadMutation } from "../api/lead-api";
import FormAutoCompleteSelect from "@/components/form/FormAutoCompleteSelect";
import { COUNTRIES_LIST } from "@/lib/const/country-list";
import ReactSelect from "react-select";
import { ISource } from "../../source/model/source";
import { useGetSourceQuery } from "../../source/api/api";
import { FullScreenDialog } from "@/components/shared/DefaultDailog/FullPageDialog";
import { useGetUsersQuery } from "@/features/users/api/user-api";
import { UserType } from "@/model/SideBarMenu";
import { IUser } from "@/features/users/models/User";
import { Textarea } from "@/components/ui/textarea";
import { useGetCustomersQuery } from "@/features/customers/api/customer-api";
import { ICustomer } from "@/features/customers/models/Customer";

// Define the Zod schema
const customerFormSchema = z
  .object({
    isForExistingClient: z.boolean().optional(),
    existingClientId: z.string().optional(),
    customerType: z.enum(["Person", "Organization", "Other"]),
    leadType: z.enum(["Tender", "Pipeline"]),
    leadName: z.string().optional(),
    country: z.string().optional(),
    region: z.string().optional(),
    contactPerson: z.string().optional(),
    leadEmail: z.string().optional(), // Email validation handled in superRefine
    leadPhoneNumbers: z.array(z.string()).optional(), // Phone validation handled in superRefine
    contactPersonPhoneNumebr: z.string().optional(), // Phone validation handled in superRefine
    taskName: z.string().nonempty("Task Name is required"),
    taskDescription: z.string().nonempty("Task Description is required"),
    source: z.array(z.string()).min(1, "At least one source is required"),
    assignedUsersId: z.array(z.string()).min(1, "At least one user is required"),
  })
  .superRefine((data, ctx) => {
    if (data.isForExistingClient) {
      // Validate existingClientId
      if (!data.existingClientId) {
        ctx.addIssue({
          code: "custom",
          path: ["existingClientId"],
          message: "Existing client is required when 'Is For Existing Client' is selected.",
        });
      }
    } else {
      // Validate fields required for new clients
      if (!data.leadName) {
        ctx.addIssue({
          code: "custom",
          path: ["leadName"],
          message: "Lead Name is required for a new client.",
        });
      }

      // Validate leadEmail
      if (!data.leadEmail) {
        ctx.addIssue({
          code: "custom",
          path: ["leadEmail"],
          message: "Lead Email is required for a new client.",
        });
      } else if (!z.string().email().safeParse(data.leadEmail).success) {
        ctx.addIssue({
          code: "custom",
          path: ["leadEmail"],
          message: "Invalid email address.",
        });
      }

      // Validate leadPhoneNumbers
      if (!data.leadPhoneNumbers || data.leadPhoneNumbers.length === 0) {
        ctx.addIssue({
          code: "custom",
          path: ["leadPhoneNumbers"],
          message: "At least one lead phone number is required for a new client.",
        });
      } else {
        data.leadPhoneNumbers.forEach((phone, index) => {
          if (!isValidPhoneNumber(phone)) {
            ctx.addIssue({
              code: "custom",
              path: ["leadPhoneNumbers", index],
              message: "Invalid phone number.",
            });
          }
        });
      }

      // Validate country
      if (!data.country) {
        ctx.addIssue({
          code: "custom",
          path: ["country"],
          message: "Country is required for a new client.",
        });
      }

      // Validate region
      if (!data.region) {
        ctx.addIssue({
          code: "custom",
          path: ["region"],
          message: "Region is required for a new client.",
        });
      }

      // Validate contactPerson
      if (!data.contactPerson) {
        ctx.addIssue({
          code: "custom",
          path: ["contactPerson"],
          message: "Contact Person is required for a new client.",
        });
      }

      // Validate contactPersonPhoneNumebr
      if (!data.contactPersonPhoneNumebr) {
        ctx.addIssue({
          code: "custom",
          path: ["contactPersonPhoneNumebr"],
          message: "Contact Person's Phone Number is required for a new client.",
        });
      } else if (!isValidPhoneNumber(data.contactPersonPhoneNumebr)) {
        ctx.addIssue({
          code: "custom",
          path: ["contactPersonPhoneNumebr"],
          message: "Invalid contact person's phone number.",
        });
      }
    }
  });


// Define the type for the form values
type CustomerFormValues = z.infer<typeof customerFormSchema>;

const defaultValues: Partial<CustomerFormValues> = {
  isForExistingClient: false,
  customerType: "Person",
  leadType: "Pipeline",
  leadName: "",
  leadEmail: "",
  leadPhoneNumbers: [""],
  source: [],
  assignedUsersId: [],
};

type AddCustomerProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
};

const customerTypes = [
  { label: "Person", value: "Person" },
  { label: "Organization", value: "Organization" },
  { label: "Other", value: "Other" },
];

const leadTypes = [
  { label: "Pipeline", value: "Pipeline" },
  { label: "Tender", value: "Tender" },
];

const NewLead = ({ openForm, setOpenForm }: AddCustomerProps) => {
  const [isForExistingClient, setIsForExistingClient] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([""]);
  const { data: sourceList } = useGetSourceQuery({});
  const { data: usersList } = useGetUsersQuery({
    data: { userType: UserType.Staff },
  });
  const { data: existingClients } = useGetCustomersQuery(
    {
      url: "/clients/SearchClients",
      method: "GET",
      data: {
        pageNumber: 1,
        pageSize: 100000,
        clientName: "",
      },
    }
  );

  const [CreatLead, { isLoading, isSuccess }] = useCreateLeadMutation();

  const form = useForm<CustomerFormValues>({
    resolver: zodResolver(customerFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const {
    formState: { errors },
    reset,
  } = form;

  const onSubmit = async (data: CustomerFormValues) => {
    const payload = data.isForExistingClient
      ? {
          // Payload for existing client
          id: data.existingClientId,
          isForExistingClinet: true,
          taskName: data.taskName,
          taskDescription: data.taskDescription,
          source: data.source,
          assignedUsersId: data.assignedUsersId,
          leadType: data.leadType,
        }
      : {
          // Payload for new client
          isForExistingClinet: false,
          clientName: data.leadName,
          leadType: data.leadType,
          clientType: data.customerType,
          email: data.leadEmail,
          phoneNumbers: data.leadPhoneNumbers,
          country: data.country,
          region: data.region,
          contactPerson: data.contactPerson,
          contactNumber: data.contactPersonPhoneNumebr,
          taskName: data.taskName,
          taskDescription: data.taskDescription,
          source: data.source,
          assignedUsersId: data.assignedUsersId,
        };
  
    // Call the API with the constructed payload
    await CreatLead({ data: payload });
  };
  

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };

  const removePhoneNumber = (index: number) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  const updatePhoneNumber = (value: string, index: number) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  useEffect(() => {
    form.setValue("leadPhoneNumbers", phoneNumbers);
  }, [phoneNumbers, form]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpenForm(false);
      Notify("success", "Potential Client Added Successfully!");
    }
  }, [isSuccess]);

  return (
    <FullScreenDialog
      title="New Potential Client"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          className="px-2 py-8 lg:px-8 space-y-8 flex flex-col gap-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Is For Existing Client */}
            <FormField
              control={form.control}
              name="isForExistingClient"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2">
                  {/* Flex row: checkbox and label */}
                  <FormControl>
                    <Input
                      type="checkbox"
                      checked={isForExistingClient}
                      onChange={(e) => {
                        setIsForExistingClient(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                      className="h-4 w-4" // Make checkbox smaller
                    />
                  </FormControl>
                  <FormLabel className="m-0">Is For Existing Customer?</FormLabel>
                </FormItem>
              )}
            />

            {/* Existing Client Selection */}
            {isForExistingClient && (
              <FormField
                control={form.control}
                name="existingClientId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Customer</FormLabel>
                    <FormControl>
                      <ReactSelect
                        options={existingClients?.list.map((client: ICustomer) => ({
                          label: client.clientName,
                          value: client.id,
                        }))}
                        value={{
                          label: existingClients?.list.find(
                            (c: ICustomer) => c.id === field.value
                          )?.clientName,
                          value: field.value,
                        }}
                        onChange={(selected) => field.onChange(selected?.value)}
                        placeholder="Select Customer"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
            control={form.control}
            name="leadType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lead Type<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Lead Type" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {leadTypes.map((type) => (
                        <SelectItem key={type.value} value={type.value}>
                          {type.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
            />

             {
              !isForExistingClient && (
              <FormField
                  control={form.control}
                  name="customerType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Customer Type<span className="text-red-500 ml-1">*</span></FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Customer Type" />
                          </SelectTrigger>
                          <SelectContent className="z-9999">
                            {customerTypes.map((type) => (
                              <SelectItem key={type.value} value={type.value}>
                                {type.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )
             }

            {
              !isForExistingClient && (
            <FormField
            control={form.control}
            name="leadName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lead Name<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter Lead Name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
              )
            }

            {
              !isForExistingClient && (
            <FormField
            control={form.control}
            name="leadEmail"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lead Email<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input type="email" {...field} placeholder="Enter Email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
              )
            }
            
            {
              !isForExistingClient && 
              phoneNumbers.map((phoneNumber, index) => (
                <div key={index} className="flex items-center gap-2">
                  <FormItem className="flex-1">
                    <FormLabel>Phone Number {index + 1}<span className="text-red-500 ml-1">*</span></FormLabel>
                    <div className="flex gap-2 items-center w-full">
                      <div className="relative flex-1">
                        <PhoneInput
                          value={phoneNumber}
                          onChange={(value) =>
                            updatePhoneNumber(value || "", index)
                          }
                          className="flex-1"
                        />
                        {phoneNumbers.length > 1 && (
                          <Button
                            variant={"destructive"}
                            className="absolute -top-2 right-0 rounded-full w-min h-min p-0"
                          >
                            <MinusCircleIcon
                              className="h-5 w-5"
                              onClick={() => removePhoneNumber(index)}
                            />
                          </Button>
                        )}
                      </div>
                      {phoneNumbers.length - 1 === index && (
                        <Button className="rounded-full bg-primary w-min h-min p-0">
                          <PlusCircleIcon
                            className="h-5 w-5"
                            onClick={addPhoneNumber}
                          />
                        </Button>
                      )}
                    </div>
                    {errors.leadPhoneNumbers?.[index] && (
                      <p className="text-red-500">
                        {errors.leadPhoneNumbers[index]?.message}
                      </p>
                    )}
                  </FormItem>
                </div>
              ))
            }
            {
              !isForExistingClient && (
                <FormAutoCompleteSelect
                  control={form.control}
                  name="country"
                  options={COUNTRIES_LIST}
                  label="Country"
                  required={true}
                />
              )
            }
            {
              !isForExistingClient && (
                <FormField
                  control={form.control}
                  name="region"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Region<span className="text-red-500 ml-1">*</span></FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter Region" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )
            }

            {
              !isForExistingClient && (
                <FormField
                  control={form.control}
                  name="contactPerson"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contact Person<span className="text-red-500 ml-1">*</span></FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter Contact Person Name" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )
            }

            {
              !isForExistingClient && (
                <FormField
                  control={form.control}
                  name="contactPersonPhoneNumebr"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contact Person Phone<span className="text-red-500 ml-1">*</span></FormLabel>
                      <PhoneInput {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )
            }

            {/* Source */}
            <FormField
              control={form.control}
              name="source"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source<span className="text-red-500 ml-1">*</span></FormLabel>
                  <ReactSelect
                    isMulti
                    options={sourceList?.map((source: ISource) => ({
                      label: source.sourceName,
                      value: source.id,
                    }))}
                    onChange={(selected) =>
                      field.onChange(selected.map((s) => s.value))
                    }
                    placeholder="Select Source"
                  />
                </FormItem>
              )}
            />

            {/* Assigned Users */}
            <FormField
              control={form.control}
              name="assignedUsersId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Assign To Users<span className="text-red-500 ml-1">*</span></FormLabel>
                  <ReactSelect
                    isMulti
                    options={usersList?.map((user: IUser) => ({
                      label: user.fullName,
                      value: user.id,
                    }))}
                    onChange={(selected) =>
                      field.onChange(selected.map((s) => s.value))
                    }
                    placeholder="Select Users"
                  />
                </FormItem>
              )}
            />

            {/* Task Name */}
            <FormField
              control={form.control}
              name="taskName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Task Name<span className="text-red-500 ml-1">*</span></FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter Task Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Task Description */}
            <FormField
              control={form.control}
              name="taskDescription"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Task Description<span className="text-red-500 ml-1">*</span></FormLabel>
                  <Textarea {...field} placeholder="Enter Task Description" />
                  <FormMessage />
                </FormItem>
              )}
            />

          </div>

          {/* Submit Button */}
          <CustomButton isLoading={isLoading} type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </FullScreenDialog>
  );
};

export default NewLead;
