import React, { SetStateAction, useEffect } from "react";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { useEditAlertTypeMutation } from "../api/alert-api";
import { IAlertType } from "../models/alert";

import { NotificationOptions } from "@/model/enums";
import FormMultiSelect from "@/components/form/FormMultiSelect";

const alertTypeFormSchema = z.object({
  alertName: z.string().nonempty("Alert Name is required"),
  remark: z.string().nonempty("Remark is required"),
  notificationOption: z.array(z.nativeEnum(NotificationOptions)).optional(),
});

type AlertTypeFormValues = z.infer<typeof alertTypeFormSchema>;

type EditAlertTypeProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  alertType: IAlertType;
};

const EditAlertType = ({
  openForm,
  setOpenForm,
  alertType,
}: EditAlertTypeProps) => {
  const [addAlrtType, { isLoading, isSuccess }] = useEditAlertTypeMutation();

  const defaultValues: AlertTypeFormValues = {
    alertName: alertType.alertName,
    remark: alertType.remark,
    notificationOption:
      (alertType.notificationOption as NotificationOptions[]) ?? [],
  };

  const form = useForm<AlertTypeFormValues>({
    resolver: zodResolver(alertTypeFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: AlertTypeFormValues) => {
    await addAlrtType({
      data: {
        id: alertType.id,
        alertName: data.alertName,
        remark: data.remark,
        notificationOption: data.notificationOption,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      setOpenForm(false);
      Notify("success", "Alert Type Edited Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog
      open={openForm}
      title="Edit Alert Type"
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="alertName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>
                  Alert Name<span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Alert Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="remark"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>
                  Remark<span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Remark" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormMultiSelect
            form={form}
            control={form.control}
            formLabel="Notification Options"
            name="notificationOption"
            options={Object.entries(NotificationOptions)
              .filter(([key]) => key !== "None" && key !== "All")
              .map(([key]) => ({
                label: key,
                value:
                  NotificationOptions[key as keyof typeof NotificationOptions],
              }))}
          />

          <CustomButton
            disabled={!form.formState.isValid}
            isLoading={isLoading}
            type="submit"
          >
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditAlertType;
