import { Button } from "@/components/ui/button";
import { Pencil, Trash2 } from "lucide-react";
import { IconButton } from "@mui/material";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { IAlertType } from "../models/alert";
import {
  useDeleteAlertTypeMutation,
  useGetAlertTypeQuery,
} from "../api/alert-api";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useEffect, useState } from "react";
import { DefaultPage } from "@/components/shared/DefaultPage";
import AddAlertType from "../components/AddAlertType";
import EditAlertType from "../components/EditAlertType";
import { Notify } from "@/components/shared/Notification/notify";
import { Badge } from "@/components/ui/badge";

export function AlertTypePage() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedAlertType, setSelectedAlertType] = useState<IAlertType | null>(
    null
  );

  const { data: alertTypeList, isLoading: isAlertFetchLoading } =
    useGetAlertTypeQuery();
  const [
    deleteAlertType,
    { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess },
  ] = useDeleteAlertTypeMutation();

  const alertTypeListColumns: MRT_ColumnDef<IAlertType>[] = [
    {
      accessorKey: "alertName",
      header: "Alert name",
    },
    {
      accessorKey: "remark",
      header: "Remark",
    },
    {
      accessorKey: "notificationOption",
      header: "Notification Option",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-1">
            {row.original.notificationOption?.map((option) => (
              <Badge key={option}>{option}</Badge>
            ))}
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      Cell: ({ row }) => {
        return (
          <div className="flex gap-4 items-center">
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setSelectedAlertType(row.original);
                setOpenEditDialog(true);
              }}
            >
              <Pencil size={14} className="mr-2" />
              Edit
            </Button>
            <IconButton
              onClick={() => {
                setSelectedAlertType(row.original);
                setOpenDeleteModal(true);
              }}
            >
              <Trash2 color="red" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleDeleteCase = async () => {
    await deleteAlertType({
      data: {
        id: selectedAlertType?.id,
      },
    });
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setOpenDeleteModal(false);
      Notify("success", "Alert Type Deleted");
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <DefaultPage
        headerTitle="Calendar Type"
        headerActionButton={
          <Button onClick={() => setOpenDialog(true)}>
            Add New Calendar Type
          </Button>
        }
      >
        <MaterialReactTable
          columns={alertTypeListColumns}
          data={alertTypeList ?? []}
          state={{ density: "compact", isLoading: isAlertFetchLoading }}
        />
      </DefaultPage>
      {openDialog && (
        <AddAlertType openForm={openDialog} setOpenForm={setOpenDialog} />
      )}
      {openEditDialog && selectedAlertType && (
        <EditAlertType
          openForm={openEditDialog}
          setOpenForm={setOpenEditDialog}
          alertType={selectedAlertType}
        />
      )}
      {openDeleteDialog && selectedAlertType && (
        <ConfirmModal
          message="Are you Sure you want to delete this alert type?"
          title="Delete Alert Type"
          modalOpen={openDeleteDialog}
          setModalOpen={() => {
            setOpenDeleteModal(false);
          }}
          type="error"
          customOkText="Delete"
          onCancel={() => {
            setOpenDeleteModal(false);
          }}
          onOk={() => handleDeleteCase()}
          loading={isDeleteLoading}
        />
      )}
    </>
  );
}
