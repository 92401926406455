import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface SelectInputProps {
  label: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value,
  options,
  onChange,
  disabled,
  required = false,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      required={required}
      fullWidth
      margin="normal"
      size="small"
      variant="filled"
      sx={{
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff",
          border: 1,
          borderColor: "#ced4da",
          borderBottomColor: "#ced4da",
        },
        "& .MuiFilledInput-root:hover": {
          borderColor: "#fff",
        },
        "& .MuiFilledInput-underline:before": {
          borderBottom: "none",
        },
        "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
        "& .MuiFilledInput-underline:after": {
          borderBottom: "none",
        },
      }}
    >
      <Typography variant="caption" id={label}>
        {label}
      </Typography>
      <Select
        labelId={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        displayEmpty
        placeholder={label}
        input={<OutlinedInput />}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => {
          if (!selected) {
            return label;
          }
          return <span>{selected}</span>;
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>Select {label}</FormHelperText> */}
    </FormControl>
  );
};

export default SelectInput;
