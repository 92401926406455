import { useState } from "react";
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UnpaidExpense } from "../component/UnpaidExpense";
import { PaidExpense } from "../component/PaidExpense";
import { VoidedExpense } from "../component/VoidedExpense";

export const Expense = () => {
  const [currentTab, setCurrentTab] = useState<string>("UnPaid");

  const handleTabChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <DefaultPageHeader 
      headerTitle="Expense" 
      />
      <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="UnPaid">UnPaid</TabsTrigger>
          <TabsTrigger value="Paid">Paid</TabsTrigger>
          <TabsTrigger value="Voided">Voided</TabsTrigger>
        </TabsList>
        <TabsContent value="UnPaid">
          <UnpaidExpense /> 
        </TabsContent>
        <TabsContent value="Paid">
          <PaidExpense/>
        </TabsContent>
        <TabsContent value="Voided">
          <VoidedExpense/>
        </TabsContent>
      </Tabs>
    </>
  );
}; 