import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { Dashboard } from "../model/dashboard";

export const dashboardApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getFinanceDashboard: builder.query<Dashboard, QueryRequest>({
      query: (params) => ({
        url: `/dashboard/revenue-expense-summary`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Finance"],
    })
  }),
});

export const {
  useGetFinanceDashboardQuery,
} = dashboardApi;
