import * as React from "react";
import {
  IWorkFlow,
  IWorkFlowParameterWithValues,
} from "@/features/workflows/Case/models/workflow";
import dayjs, { Dayjs } from "dayjs";
import FormField from "./form-components/FormField";
import { Alert, Box, Snackbar } from "@mui/material";
import { Button } from "@/components/ui/button";

import {
  useUpdateTaskDetailsAndStatusMutation,
  useUpdateTaskDetailsFileMutation,
} from "../api/task-command";
import { FullScreenDialog } from "@/components/shared/DefaultDailog/FullPageDialog";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { useUpdateSalesTaskDetailsAndStatusMutation } from "@/features/sales/api/pipe-line-wf-command";

// Define a flexible type for form values
type FormValues = {
  [key: string]: string | Dayjs | string[] | File | null;
};

interface DynamicFormProps {
  parameters: IWorkFlowParameterWithValues[];
  taskId: string;
  workFlowId: string;
  currentWorkFlow: IWorkFlow;
  taskStatus: string;
  workflow: IWorkFlow;
  isSalesOperation?: boolean;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  parameters,
  taskId,
  workFlowId,
  currentWorkFlow,
  taskStatus,
  workflow,
  isSalesOperation = false,
}) => {
  // Initialize form values based on parameter types
  const [formValues, setFormValues] = React.useState<FormValues>(() =>
    parameters.reduce((acc, param) => {
      let value;
      switch (param.parameterType.toLowerCase()) {
        case "date":
          value = param.value ? dayjs(param.value as string) : null;
          break;
        case "multiselect":
        case "multi-select":
          value = JSON.parse(param.value as string);
          break;
        default:
          value = param.value ? (param.value as string) : "";
          break;
      }
      acc[param.id] = value;
      return acc;
    }, {} as FormValues)
  );
  const { session } = useAuth();

  // Handle changes to form fields
  const handleChange = (
    id: string,
    value: string | Dayjs | string[] | File | null
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    console.log("Form Values:", formValues); // Log the form values
    const taskDetails = parameters.map((parameter) => ({
      workFlowId: workFlowId,
      workFlowParameterId: parameter.id,
      value:
        formValues[parameter.id] instanceof Array
          ? JSON.stringify(formValues[parameter.id] as string[])
          : (formValues[parameter.id] as string),
    }));

    const payload = {
      taskId: taskId,
      taskDetails: [...taskDetails],
    };
    await UpdateTaskDetailsAndStatus({
      data: {
        taskId: taskId,
        taskDetails: payload.taskDetails,
      },
    });
  };

  const [openFullDialog, setOpenFullDialog] = React.useState(false);

  const resetsFormValues = () => {
    setFormValues(
      parameters.reduce((acc, param) => {
        switch (param.parameterType.toLowerCase()) {
          case "date":
            acc[param.id] = null;
            break;
          case "multiselect":
          case "multi-select": {
            acc[param.id] = JSON.parse(param.value as string);
            break;
          }

          default:
            acc[param.id] = param.value ? (param.value as string) : "";
            break;
        }
        return acc;
      }, {} as FormValues)
    );
  };
  const handleCloseFullScreenDialog = () => {
    setOpenFullDialog(false);
    resetsFormValues();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseJSON = (value: string | null): any[] => {
    try {
      // console.lg(value);
      return value ? JSON.parse(value) : [];
    } catch {
      return [];
    }
  };
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [
    UpdateTaskDetailsAndStatus,
    {
      isSuccess: isUpdatingParameterSuccess,
      isLoading: isUpdatingParameterLoading,
    },
  ] = useUpdateTaskDetailsAndStatusMutation();
  const [
    UpdateSalesTaskDetailsAndStatus,
    {
      isSuccess: isUpdateSalesOperaticSuccess,
      isLoading: isUpdateSalesOperationLoading,
    },
  ] = useUpdateSalesTaskDetailsAndStatusMutation();
  const [
    updateTaskFile,
    { isLoading: isUpdateFileLoading, isSuccess: isUpdateFileSuccess },
  ] = useUpdateTaskDetailsFileMutation();

  const handleUpdateParameterValue = async (
    parameter: IWorkFlowParameterWithValues
  ) => {
    const value = formValues[parameter.id];

    // Check if the value is a File or an array of Files
    const isFile = value instanceof File;
    const isFileArray =
      Array.isArray(value) &&
      (value as unknown[]).every((item) => item instanceof File);

    if (isFile || isFileArray) {
      const formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("workFlowId", workFlowId);
      formData.append("workFlowParameterId", parameter.id);

      if (isFile) {
        formData.append("file", value);
      } else if (isFileArray) {
        value.forEach((file, index) => {
          formData.append(`files[${index}]`, file);
        });
      }

      try {
        await updateTaskFile(formData).unwrap();
        // Optionally, handle success (e.g., show a notification)
      } catch (error) {
        // Optionally, handle error (e.g., show an error message)
        console.error("Failed to update task file:", error);
      }
      return;
    }

    // For non-file values, prepare the data object
    const taskDetail = {
      workFlowId: workFlowId,
      workFlowParameterId: parameter.id,
      value: Array.isArray(value)
        ? JSON.stringify(value as string[])
        : (value as string | Dayjs | null)?.toString(), // Ensure value is a string
    };

    try {
      if (isSalesOperation) {
        await UpdateSalesTaskDetailsAndStatus({
          data: {
            taskId: taskId,
            taskDetails: [{ ...taskDetail, value: taskDetail.value ?? "" }],
          },
        });
      } else {
        await UpdateTaskDetailsAndStatus({
          data: {
            taskId: taskId,
            taskDetails: [
              {
                value: taskDetail.value ?? "",
                workFlowId: taskDetail.workFlowId,
                workFlowParameterId: taskDetail.workFlowParameterId,
              },
            ],
          },
        });
      }
      // Optionally, handle success (e.g., show a notification)
    } catch (error) {
      // Optionally, handle error (e.g., show an error message)
      console.error("Failed to update task details:", error);
    }
  };

  // make false to open snackbar after 2 seconds
  React.useEffect(() => {
    if (openSnackBar) {
      const timer = setTimeout(() => {
        setOpenSnackBar(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [openSnackBar]);

  React.useEffect(() => {
    if (
      isUpdatingParameterSuccess ||
      isUpdateSalesOperaticSuccess ||
      isUpdateFileSuccess
    ) {
      setOpenSnackBar(true);
      setOpenFullDialog(false);
    }
    if (isUpdateFileSuccess) {
      window.location.reload();
    }
  }, [
    isUpdateFileSuccess,
    isUpdateSalesOperaticSuccess,
    isUpdatingParameterSuccess,
  ]);

  const canEdit = () => {
    const isClient = session?.userInfo.userType === UserType.Client;
    const statuses = ["Finished", "Cancelled", "Suspended"];
    const isUserPermittedToEdit = workflow.isCurrentUserEditPermitted;

    if (workflow.isEditByPermission && !isUserPermittedToEdit) {
      return false;
    }
    if (statuses.includes(taskStatus)) {
      return false;
    }
    if (isClient) {
      return false;
    }
    if (workflow.id === currentWorkFlow.id) {
      return true;
    }

    // return workflow.canEditAtAnyStage;
  };

  return (
    <div>
      {canEdit() && (
        <div className="flex justify-end my-3">
          <Button onClick={() => setOpenFullDialog(true)}>Edit All</Button>
        </div>
      )}
      <Box flexGrow={1}>
        <div className="grid sm:grid-cols-1 gap-1 lg:grid-cols-2">
          {parameters
            // sort by orderNO
            .slice()
            .sort((a, b) => a.orderNo - b.orderNo)
            .map((parameter, index) => (
              <div
                className="flex items-center gap-1 align my-4"
                key={parameter.id}
              >
                <span className="mx-2">{index + 1}</span>
                <div className="w-[100%]">
                  <FormField
                    id={parameter.id}
                    label={parameter.parameterName}
                    type={parameter.parameterType}
                    value={formValues[parameter.id]}
                    options={parseJSON(parameter.paramaterValues)}
                    onChange={handleChange}
                    disabled={!canEdit()}
                    required={parameter.isRequired}
                  />
                  {canEdit() && (
                    <CustomButton
                      disabled={
                        formValues[parameter.id] === "" ||
                        formValues[parameter.id] === null
                      }
                      type="button"
                      // size={"sm"}
                      variant={"outline"}
                      onClick={() => handleUpdateParameterValue(parameter)}
                      isLoading={
                        isUpdatingParameterLoading || isUpdateFileLoading
                      }
                    >
                      Save
                    </CustomButton>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Box>
      {session?.userInfo.userType !== UserType.Client && (
        <FullScreenDialog
          title="Edit All Parameter"
          open={openFullDialog}
          onClose={handleCloseFullScreenDialog}
        >
          <form onSubmit={handleSubmit} className="p-6 bg-white rounded-md">
            <Box flexGrow={1}>
              <div className="grid sm:grid-cols-1 gap-1 lg:grid-cols-2">
                {parameters
                  .slice()
                  .sort((a, b) => a.orderNo - b.orderNo)
                  .map((parameter) => (
                    <div className="flex items-center gap-1" key={parameter.id}>
                      <FormField
                        id={parameter.id}
                        label={parameter.parameterName}
                        type={parameter.parameterType}
                        value={formValues[parameter.id]}
                        options={parseJSON(parameter.paramaterValues)}
                        onChange={handleChange}
                        disabled={!canEdit()}
                        required={parameter.isRequired}
                      />
                    </div>
                  ))}
              </div>
              <div className="flex justify-center">
                <CustomButton
                  className="w-59"
                  isLoading={
                    isUpdatingParameterLoading || isUpdateSalesOperationLoading
                  }
                >
                  Submit
                </CustomButton>
              </div>
            </Box>
          </form>
        </FullScreenDialog>
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackBar(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Task Parameter Updated successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DynamicForm;
