import { DefaultPage } from "@/components/shared/DefaultPage";
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useState } from "react";
import CreateUser from "./CreateUser";
import { Button } from "@/components/ui/button";
import { StaffUserList } from "../componets/StaffUserList";
import { ClientUserList } from "../componets/ClientUserList";


export function User() {
  const [openAddUser, setOpenAddUser] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>("staff");

  return (
    <>
      <DefaultPage 
      headerTitle="List of Users"
      headerActionButton={selectedTab === "staff" && <Button className="" onClick={() => setOpenAddUser(true)}>Add User</Button>}
      >
         <Tabs
          defaultValue={selectedTab}
          className=""
          onValueChange={setSelectedTab}
        >
          <TabsList className="">
          <TabsTrigger value="staff">Staff</TabsTrigger>
          <TabsTrigger value="client">Client</TabsTrigger>
          </TabsList>
          <TabsContent value="staff">
            <StaffUserList/>
          </TabsContent>
          <TabsContent value="client">
            <ClientUserList/>
          </TabsContent>
        </Tabs>
      </DefaultPage>
      {
        openAddUser && <CreateUser openForm={openAddUser} setOpenForm={setOpenAddUser}/>
      }
    </>
  );
}