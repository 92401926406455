import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IUser } from "../models/User";
import { IPermission, IRole, PermissionsWithGroup } from "../models/Role";

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<IRole[], void>({
      query: () => ({
        url: "/roles",
        method: "GET",
      }),
      providesTags: ["Roles"], // Provides a tag for caching
    }),
    getRoleById: builder.query<IRole, string>({
      query: (id) => ({
        url: `/roles/${id}`,
        method: "GET",
      }),
      providesTags: ["Roles"], // Provides a tag for caching
    }),
    getUsers: builder.query<IUser[], QueryRequest>({
      query: (params) => ({
        url: "/users/SearchUsers",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Users"], // Provides a tag for caching
    }),
    getUserDetail: builder.query<IUser, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
      providesTags: ["User"], // Provides a tag for caching
    }),
    createUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Users"],
    }),
    editUserInfo: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: "PUT",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Users", "User"],
    }),
    createRole: builder.mutation<IRole, QueryRequest>({
      query: (params) => ({
        url: "/roles",
        method: "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Roles"],
    }),
    changePassword: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
    }),
    getPermissions: builder.query<IPermission[], QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
    }),
    deleteRole: builder.mutation<string, string>({
      query: (id) => ({
        url: `/roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Roles"],
    }),
    deleteUser: builder.mutation<string, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    editRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/roles/updateRole",
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Roles"],
    }),
    editUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "PUT",
        data: params.data,
      }),
      invalidatesTags: ["User", "Users"],
    }),
    deActivateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/DeactivateUser/${params.data.id}`,
        method: "POST",
      }),
      invalidatesTags: ["User", "Users"],
    }),
    activateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/ActivateUser/${params.data.id}`,
        method: "POST",
      }),
      invalidatesTags: ["User", "Users"],
    }),
    updateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/update`,
        method: "PUT",
        data: { ...params.data },
      }),
      invalidatesTags: ["User", "Users"],
    }),
    updateUserRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["User"],
    }),
    updateUserClient: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/users/UpdateUserClients",
        method: "PUT",
        data: { ...params.data },
      }),
      invalidatesTags: ["User"],
    }),
    getUsersByRoleId: builder.query<IUser[], QueryRequest>({
      query: (params) => ({
        url: `/roles/GetUsersByRoleId/`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Roles"], // Provides a tag for caching
    }),
    getPermissionsByRoleId: builder.query<IPermission[], QueryRequest>({
      query: (params) => ({
        url: `/roles/GetPermissionByRoleId`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Roles"], // Provides a tag for caching
    }),
    getAllPermissions: builder.query<PermissionsWithGroup[], void>({
      query: () => ({
        url: "/permissions",
        method: "GET",
      }),
    }),
    onOffPermissions: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/roles/OnOffPermissions",
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Roles"], // Invalidates tags related to roles
    }),
    addRoleMember: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/userRoles/AddMemeber",
        method: "PUT",
        data: { ...params.data },
      }),
      invalidatesTags: ["User", "Roles"],
    }),
    removeRoleMember: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/userRoles/RemoveMemeber",
        method: "PUT",
        data: { ...params.data },
      }),
      invalidatesTags: ["User", "Roles"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetRolesQuery,
  useGetUserDetailQuery,
  useCreateUserMutation,
  useGetPermissionsQuery,
  useCreateRoleMutation,
  useChangePasswordMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useUpdateUserRoleMutation,
  useEditUserInfoMutation,
  useDeActivateUserMutation,
  useUpdateUserMutation,
  useActivateUserMutation,
  useUpdateUserClientMutation,
  useGetRoleByIdQuery,
  useGetAllPermissionsQuery,
  useGetPermissionsByRoleIdQuery,
  useGetUsersByRoleIdQuery,
  useAddRoleMemberMutation,
  useRemoveRoleMemberMutation,
  useOnOffPermissionsMutation,
  useDeleteUserMutation
} = userApi;
