/* eslint-disable react-hooks/exhaustive-deps */
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import AlertCardList from "../components/ALertCardList";
import EventCalendar from "../components/EventCalendar";
import { Button } from "@/components/ui/button";
import { AddGenericAlertDialog } from "../components/AddGenericAlertDialog";
import AlertList from "../components/ALertList";

const AlertsPage = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<string>("list");
  const [openCreateAlert, setOpenCreatAlert] = useState<boolean>(false);
  return (
    <>
      <DefaultPageHeader
        headerTitle="Calendar"
        headerActionButton={
          <Button onClick={() => setOpenCreatAlert(true)}>Create Alert</Button>
        }
      />
      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="">
          <TabsTrigger value="list">List View</TabsTrigger>
          <TabsTrigger value="grid">Grid View</TabsTrigger>
          <TabsTrigger value="calendar">Calendar View</TabsTrigger>
        </TabsList>
        <TabsContent value="list">
          <AlertList />
        </TabsContent>
        <TabsContent value="grid">
          <AlertCardList />
        </TabsContent>
        <TabsContent value="calendar">
          <EventCalendar />
        </TabsContent>
      </Tabs>
      {openCreateAlert && (
        <AddGenericAlertDialog
          openAlertDialog={openCreateAlert}
          setOpenAlertDialog={(state) => setOpenCreatAlert(state)}
        />
      )}
    </>
  );
};

export default AlertsPage;
