import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useEffect } from "react";
import { useGetAlertTypeQuery } from "@/features/alert/api/alert-api";
import { useAddAlertFortTaskMutation } from "@/features/task/api/task-command";
import { AlertFrom } from "@/features/task/models/Task";
import { Notify } from "@/components/shared/Notification/notify";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IUser } from "@/features/users/models/User";
import { useGetUsersQuery } from "@/features/genericTask/api/generic-task-api";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

type Props = {
  openAlertDialog: boolean;
  setOpenAlertDialog: (value: boolean) => void;
};

// Define Zod schema for form validation
const AddAlertSchema = z.object({
  alertTypeId: z.string().nonempty("Alert type is required"),
  dueDate: z.date(),
  daysBeforeAlert: z.number().nonnegative("Must be a non-negative number"),
  message: z.string().nonempty("Message is required"),
  alertTitle: z.string().nonempty("Alert Title is required"),
  users: z.array(z.object({
    id: z.string(),
    fullName: z.string(),
  })).optional(),
  snoozeUnit: z.enum(["None", "Minutes", "Hours", "Days"]),
  snoozeDuration: z.number().nonnegative("Must be a non-negative number"),
});

type AddAlertFormInputs = z.infer<typeof AddAlertSchema>;

const snoozeUnits = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Minutes",
    value: "Minutes",
  },
  {
    label: "Hours",
    value: "Hours",
  },
  {
    label: "Days",
    value: "Days",
  },
];

export const AddGenericAlertDialog = ({
  openAlertDialog,
  setOpenAlertDialog,
}: Props) => {
  const { session } = useAuth();
  const methods = useForm<AddAlertFormInputs>({
    resolver: zodResolver(AddAlertSchema),
  });

  const { control, handleSubmit, reset } = methods;

  const [addAlert, { isSuccess, isLoading }] = useAddAlertFortTaskMutation();
  const { data: alertTypes } = useGetAlertTypeQuery();
  const { data: usersList, isLoading: isUserFetchingLoading } =
    useGetUsersQuery({
      data: {
        organizationId: session?.userInfo.organizationId,
        userType: UserType.Staff,
      },
    });

  const onSubmit = async (data: z.infer<typeof AddAlertSchema>) => {
    await addAlert({
      data: {
        alertFrom: AlertFrom.Other,
        daysBeforeAlert: data.daysBeforeAlert,
        message: data.message,
        dueDate: data.dueDate,
        alertTypeId: data.alertTypeId,
        users: data?.users?.map((user) => user.id),
        snoozeUnit: data.snoozeUnit,
        snoozeDuration: data.snoozeDuration,
        alertTitle: data.alertTitle
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenAlertDialog(false);
      Notify("success", "Alert Created Successfully");
      reset();
    }
  }, [isSuccess, setOpenAlertDialog, reset]);

  const renderSelectUsers = (field: any) => (
    <Autocomplete
      multiple
      options={usersList || []}
      getOptionLabel={(option: IUser) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Users"
          placeholder="Users"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isUserFetchingLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );

  return (
    <DefaultDialog
      title="Add Alert"
      open={openAlertDialog}
      onClose={() => setOpenAlertDialog(false)}
    >
      <Form {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <FormField
            name="alertTypeId"
            control={methods.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Alert Type<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Alert Type" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {alertTypes?.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.alertName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="dueDate"
            control={control}
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Due Date<span className="text-red-500 ml-1">*</span></FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          "pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value
                          ? format(field.value, "PPP")
                          : "Pick a date"}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent align="start" className="w-auto p-0 z-99999">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="daysBeforeAlert"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Days Before Alert<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Days before alert"
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="snoozeUnit"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Snooze Unit<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Snooze Unit" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {snoozeUnits.map((type, index) => (
                        <SelectItem key={index} value={type.value}>
                          {type.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="snoozeDuration"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Snooze Duration<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Snooze Duration"
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="alertTitle"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Alert Title<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Alert Title" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="message"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Your Message<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Type your message here."
                    {...field}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="users"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Assign User</FormLabel>
                <FormControl>{renderSelectUsers(field)}</FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex gap-2 mt-2">
            <CustomButton
              variant="primary"
              type="submit"
              isLoading={isLoading}
              className="w-full"
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
