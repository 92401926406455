import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PipelineWorkflowDetailTab } from "../components/PipelineWorkflowDetailTab";
import { useGetSalesTaskDetailsByTaskIdQuery } from "../../api/pipe-line-wf-query";
import { SalesTaskAlertNotificationList } from "../components/SalesTaskAlertNotificationList";
import { SalesTaskHistory } from "../components/SalesTaskHistory";
import { TaskMessaging } from "@/features/task/components/TaskMessaging";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";

export const PipeLineWorkflowDetailPage = () => {
  const { salesTaskId } = useParams();
  const [selectedTab, setSelectedTab] = useState("detail");
  const { data: taskDetail } = useGetSalesTaskDetailsByTaskIdQuery({
    data: {
      salesTaskId: salesTaskId,
    },
  });

  return (
    <>
      <DefaultPageHeader
        headerTitle={`Task Detail`}
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name:
              taskDetail?.caseType &&
              taskDetail.caseType?.name === "Tender Management"
                ? "Tender Workflow"
                : "Pipe Line Workflow",
            url:
              taskDetail?.caseType &&
              taskDetail.caseType?.name === "Tender Management"
                ? "/sales/tender-workflow"
                : "/sales/pipeline-workflow",
          },
          {
            name: "Task Detail",
            url: "#",
          },
        ]}
        headerActionButton={
          <CircularPercentageIndicator
            value={taskDetail?.completionRate ?? 0}
            size={60}
          />
        }
      />
      {taskDetail?.task && (
        <Tabs
          defaultValue={selectedTab}
          className=""
          onValueChange={setSelectedTab}
        >
          <TabsList className="grid w-100 grid-cols-5">
            <TabsTrigger value="detail">Detail</TabsTrigger>
            <TabsTrigger value="message">Messages</TabsTrigger>
            <TabsTrigger value="alerts" className="text-red-500">
              Alerts
            </TabsTrigger>
            <TabsTrigger value="history">History</TabsTrigger>
          </TabsList>
          <TabsContent value="detail">
            {taskDetail && (
              <PipelineWorkflowDetailTab taskDetail={taskDetail} />
            )}
          </TabsContent>
          <TabsContent value="history">
            {taskDetail?.task?.id && (
              <SalesTaskHistory taskId={taskDetail.task.id} />
            )}
          </TabsContent>
          <TabsContent value="message">
            {taskDetail?.task?.id && (
              <TaskMessaging taskId={taskDetail?.task.id} />
            )}
          </TabsContent>
          <TabsContent value="alerts">
            {taskDetail?.task?.id && (
              <SalesTaskAlertNotificationList taskId={taskDetail.task.id} />
            )}
          </TabsContent>
        </Tabs>
      )}
    </>
  );
};
