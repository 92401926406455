import PageTitle from "@/components/ui/PageTitle";
import { Route } from "react-router-dom";
import { Revenue } from "./paymentCollection/pages/Revenue.tsx";
import { Expense } from "./expense/page/Expense.tsx";
import FinanceDashboard from "./Dashboard/pages/FinanceDashboard.tsx";
import { PaymentTypePage } from "../payment-types/pages/PaymentTypePage.tsx";
export default (
  <Route path="finance">
    <Route
      index
      element={
        <>
          <PageTitle title="Dashboard" />
          <FinanceDashboard />
        </>
      }
    />
    <Route path="revenue">
      <Route
        index
        element={
          <>
            <PageTitle title="Revenue" />
            <Revenue />
          </>
        }
      />
    </Route>
    <Route
        path="expense"
        element={
          <>
            <PageTitle title="Expense" />
            <Expense />
          </>
        }
      />
      <Route
        path="payment-types"
        element={
          <>
            <PageTitle title="Payment Type" />
            <PaymentTypePage />
          </>
        }
      />
  </Route>
);
