import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { ICaseWithSector, ISector } from "../models/Case";
import { CommandRequest } from "@/model/command-request";
import { IWorkFlowParameter } from "../models/workflow";
import { ISalesCaseType } from "../../models/sales-wf-case";

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCases: builder.query<ICaseWithSector[], void>({
      query: () => ({
        url: "/caseTypes",
        method: "GET",
      }),
      providesTags: ["Cases"],
    }),
    getCaseByCaseId: builder.query<ICaseWithSector, string>({
      query: (id) => ({
        url: `/caseTypes/${id}`,
        method: "GET",
      }),
      providesTags: ["Cases"],
    }),
    getCaseTypesHasWorkFlow: builder.query<ICaseWithSector[], void>({
      query: () => ({
        url: "/caseTypes/GetCaseTypesHasWorkFlow",
        method: "GET",
      }),
      providesTags: ["Cases"],
    }),
    getSectors: builder.query<ISector[], QueryRequest>({
      query: (params) => ({
        url: params?.url ?? "/sectors",
        method: params?.method ?? "GET",
      }),
      providesTags: ["Sectors"],
    }),
    getSectorsHasCase: builder.query<ISector[], void>({
      query: () => ({
        url: "/caseTypes/GetCaseTypesHasWorkFlow",
        method: "GET",
      }),
      providesTags: ["Sectors"],
    }),
    createCase: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),

    createSector: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "/sectors",
        method: params.method ?? "GET",
        data: params.data,
      }),
      invalidatesTags: ["Sectors"],
    }),
    updateSector: builder.mutation<string, CommandRequest<ISector>>({
      query: (params) => ({
        url: `/sectors/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Sectors"],
    }),
    updateCase: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/caseTypes/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    copyCase: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/caseTypes/CopyWorkFlowsBetweenCases`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
    copySector: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/sectors/CopyAllCasesAndWorkFlowsBetweenSectors`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Sectors", "Cases"],
    }),
    updateParameter: builder.mutation<
      string,
      CommandRequest<IWorkFlowParameter>
    >({
      query: (params) => ({
        url: `/workFlowParameters/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    UpdateParameterOrderNo: builder.mutation<
      string,
      CommandRequest<{ id: string; orderNo: number }[]>
    >({
      query: (params) => ({
        url: `/workFlowParameters/UpdateOrderNo`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    UpdateWorkOrderNo: builder.mutation<
      string,
      CommandRequest<{ id: string; orderNo: number }[]>
    >({
      query: (params) => ({
        url: `/WorkFlows/UpdateWorkFlowOrderNo`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    getSalesCases: builder.query<ISalesCaseType[], void>({
      query: () => ({
        url: "/salesCaseTypes",
        method: "GET",
      }),
      providesTags: ["Cases"],
    }),
  }),
});

export const {
  useGetCasesQuery,
  useGetCaseByCaseIdQuery,
  useGetSectorsQuery,
  useCreateCaseMutation,
  useCreateSectorMutation,
  useUpdateSectorMutation,
  useUpdateCaseMutation,
  useUpdateParameterMutation,
  useUpdateParameterOrderNoMutation,
  useGetCaseTypesHasWorkFlowQuery,
  useCopyCaseMutation,
  useGetSectorsHasCaseQuery,
  useCopySectorMutation,
  useUpdateWorkOrderNoMutation,
  useGetSalesCasesQuery,
} = userApi;
