export interface NotificationModel {
    id: string,
    message: string,
    time: string,
    taskId: string,
    notificationFrom: NotificationSource
  }

export enum NotificationSource {
    Task = "Task",
    PipeLine = "PipeLine",
    Tender = "Tender",
    GenericTask = "GenericTask",
    Calendar = "Calendar" // Corrected spelling from "Caledar" to "Calendar"
}