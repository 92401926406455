import { appApi } from "@/store/app-api";
import { CommandRequest } from "@/model/command-request";
import { IPaymentType, IPaymentTypeCreate } from "../models/payments";

export const paymentTypeApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentMethod: builder.mutation<string, CommandRequest<IPaymentTypeCreate>>(
      {
        query: (params) => ({
          method: "POST",
          url: `/paymentTypes`,
          data: params.data,
        }),
        invalidatesTags: ["Task", "TaskDetail", "Payment"],
      }
    ),
    updatePaymentMethod: builder.mutation<string, CommandRequest<IPaymentType>>(
      {
        query: (params) => ({
          method: "PUT",
          url: `/paymentTypes/${params.data.id}`,
          data: params.data,
        }),
        invalidatesTags: ["Task", "TaskDetail", "Payment"],
      }
    ),
    deletePaymentMethod: builder.mutation<string, string>({
      query: (params) => ({
        method: "DELETE",
        url: `/paymentTypes/${params}`,
      }),
      invalidatesTags: ["Task", "TaskDetail", "Payment"],
    }),
  }),
});

export const {
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} = paymentTypeApi;
