// CustomButton.tsx
import { ButtonHTMLAttributes, FC } from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils"; // Adjust the import path based on your project structure

// Define the button variants and sizes
type Variant = "default" | "primary" | "secondary" | "outline" | "danger";
type Size = "sm" | "md" | "lg";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  size?: Size;
  isLoading?: boolean;
  className?: string; // Add className to the props
}

const CustomButton: FC<ButtonProps> = ({
  children,
  variant = "default",
  size = "md",
  isLoading = false,
  disabled = false,
  className, // Destructure className from props
  ...props
}) => {
  return (
    <Button
      className={cn(
        // Variant Classes
        {
          "bg-primary text-white hover:bg-primary-dark": variant === "primary",
          "bg-secondary text-primary hover:bg-secondary-dark":
            variant === "secondary",
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground text-black":
            variant === "outline",
          "bg-red-600 text-white hover:bg-red-700": variant === "danger",
          // Default Variant (optional)
          "bg-default text-black hover:bg-default-dark": variant === "default",
        },
        // Size Classes
        {
          "px-2 py-1 text-sm": size === "sm",
          "px-4 py-2 text-base": size === "md",
          "px-6 py-3 text-lg": size === "lg",
        },
        // Common Classes
        "inline-flex items-center justify-center font-medium rounded-md transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2",
        // Disabled or Loading State
        (isLoading || disabled) && "opacity-50 cursor-not-allowed",
        // Additional className from props
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {children}
    </Button>
  );
};

export default CustomButton;
