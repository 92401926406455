import { DefaultPage } from "@/components/shared/DefaultPage";
import { Button } from "@/components/ui/button";
import { Check, ChevronDown, Plus } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { useGetSalesCasesQuery } from "../Case/api/case-api";
import { useEffect, useState, useMemo } from "react";
import { useLazyGetSalesWorkFlowsByCaseTypeIdQuery } from "../api/work-flow-api";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { ISalesCaseType } from "../models/sales-wf-case";
import { AddSalesWorkFlowDialog } from "./components/AddSalesWorkFlowDialog";
import { SalesWorkflowList } from "./components/SalesWorkflowList";

const SalesWorkflow = () => {
  const { data: caseList = [] } = useGetSalesCasesQuery();
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);
  const [selectedCase, setSelectedCase] = useState<ISalesCaseType | null>(null);
  const [openSector, setOpenSector] = useState<boolean>(false);
  const [
    getWorkFlowByCase,
    { data: workflows, isLoading: isFetchingWorkFlowLoading },
  ] = useLazyGetSalesWorkFlowsByCaseTypeIdQuery();

  useEffect(() => {
    if (selectedCaseId) {
      getWorkFlowByCase({ data: { caseTypeId: selectedCaseId } });
    }
  }, [getWorkFlowByCase, selectedCaseId]);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const selectedCaseName = useMemo(() => {
    return caseList.find((c) => c.id === selectedCaseId)?.name || "Select Case";
  }, [caseList, selectedCaseId]);

  return (
    <DefaultPage headerTitle="Sales Workflow">
      <div className="h-screen">
        <div className="px-4 m-3 py-2">
          <Popover open={openSector} onOpenChange={setOpenSector}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="flex justify-between w-100"
              >
                {selectedCaseName}
                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-100 p-0">
              <Command>
                <CommandInput placeholder="Search Case..." />
                <CommandList>
                  <CommandEmpty>No Sector found.</CommandEmpty>
                  <CommandGroup>
                    {caseList.map((c) => (
                      <CommandItem
                        key={c.id}
                        onSelect={() => {
                          setSelectedCaseId(c.id);
                          setSelectedCase(c);
                          setOpenSector(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedCaseId === c.id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {c.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        <Separator />
        <div className="px-4 my-2">
          {isFetchingWorkFlowLoading ? (
            <Skeleton className="h-[125px] w-full rounded-xl" />
          ) : (
            selectedCase && (
              <>
                <div className="flex justify-end">
                  <Button onClick={() => setOpenDialog(true)}>
                    <Plus size={18} className="mr-2" />
                    Add Workflow
                  </Button>
                  {openDialog && (
                    <AddSalesWorkFlowDialog
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      salesCaseTypeId={selectedCase.id}
                      currentNumberOfWorkFlows={workflows?.length ?? 0}
                    />
                  )}
                </div>
                <h3>
                  <span className="font-bold">Description</span>
                  <br />
                  <span>{selectedCase.name}</span>
                </h3>
                <span className="font-bold">Workflows</span>
                {selectedCaseId && (
                  <SalesWorkflowList selectedCaseId={selectedCaseId} />
                )}
              </>
            )
          )}
        </div>
      </div>
    </DefaultPage>
  );
};

export default SalesWorkflow;
