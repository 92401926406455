import { DefaultPage } from "@/components/shared/DefaultPage";
import { Button } from "@/components/ui/button";
import { Pencil, Plus, Trash2 } from "lucide-react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo, useState } from "react";
import { useGetPaymentTypesQuery } from "../api/payment-type-query";
import { IPaymentType } from "../models/payments";
import { AddEditPaymentTypeDialog } from "../components/AddEditPaymentTypeDialog";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { useDeletePaymentMethodMutation } from "../api/payment-type-command";
import { Badge } from "@/components/ui/badge";

export const PaymentTypePage = () => {
  const columns = useMemo<MRT_ColumnDef<IPaymentType>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "remark",
        header: "Remark",
      },
      {
        accessorKey: "incomeExepense",
        header: "Income/Expense",
      },
      {
        accessorKey: "notificationOption",
        header: "Notification Option",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-1">
              {row.original.notificationOption?.map((option) => (
                <Badge key={option}>{option}</Badge>
              ))}
            </div>
          );
        },
      },

      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ row }) => {
          return <span>{row.original.isActive ? "Active" : "Inactive"}</span>;
        },
      },
    ],
    []
  );
  const { data: paymentTypes, isLoading: isGettingPaymentTypeLoading } =
    useGetPaymentTypesQuery();
  const [selectedPayment, setSelectedPayment] = useState<IPaymentType | null>(
    null
  );
  const [openDeleteOpenConfirmModal, setOpenDeleteOpenConfirmModal] =
    useState(false);
  const [openAddEditPaymentTypeDialog, setOpenAddEditPaymentTypeDialog] =
    useState(false);
  const table = useMaterialReactTable({
    columns,
    data: paymentTypes ?? [],
    initialState: {
      density: "compact",
    },
    state: { isLoading: isGettingPaymentTypeLoading },
    rowCount: 0,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => {
      return (
        <div className="flex py-2 gap-2">
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setSelectedPayment(row.original);
              setOpenAddEditPaymentTypeDialog(true);
            }}
          >
            <Pencil size={12} className="mr-2" />
            Edit
          </Button>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setSelectedPayment(row.original);
              setOpenDeleteOpenConfirmModal(true);
            }}
            className="border-transparent"
          >
            <Trash2 size={14} className="mr-2" color="red" />
          </Button>
        </div>
      );
    },
  });
  const [deleteMethodType, { isLoading: isDeletingPaymentMethodLoading }] =
    useDeletePaymentMethodMutation();

  const handleDeletePaymentMethod = async () => {
    if (selectedPayment && selectedPayment.id) {
      await deleteMethodType(selectedPayment.id);
      setOpenDeleteOpenConfirmModal(false);
      setSelectedPayment(null);
    }
  };
  return (
    <>
      <DefaultPage
        headerTitle="Payment Type"
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: "Payment Type",
            url: "/payment-types",
          },
        ]}
        headerActionButton={
          <Button
            onClick={() => {
              setOpenAddEditPaymentTypeDialog(true);
            }}
          >
            <Plus size={20} />
            Add Payment Type
          </Button>
        }
      >
        <MaterialReactTable table={table} />
        <AddEditPaymentTypeDialog
          open={openAddEditPaymentTypeDialog}
          onClose={() => {
            setOpenAddEditPaymentTypeDialog(false);
            setSelectedPayment(null);
          }}
          selectedPaymentType={selectedPayment}
          isUpdating={selectedPayment !== null}
          key={JSON.stringify(selectedPayment)}
        />
        {openDeleteOpenConfirmModal && selectedPayment && (
          <ConfirmModal
            title="Delete Workflow"
            message="Are you Sure you want to Delete this workflow?"
            modalOpen
            setModalOpen={() => {}}
            type="error"
            onCancel={() => {
              setOpenDeleteOpenConfirmModal(false);
              setSelectedPayment(null);
            }}
            onOk={handleDeletePaymentMethod}
            loading={isDeletingPaymentMethodLoading}
          />
        )}
      </DefaultPage>
    </>
  );
};
