import { CommandRequest } from "@/model/command-request";
import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IWorkFlow, IWorkFlowParameter } from "../Case/models/workflow";

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createWorkFlow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/WorkFlows",
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    createWorkFlowParameter: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/workFlowParameters",
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    updateWorkFlow: builder.mutation<
      string,
      CommandRequest<Partial<IWorkFlow>>
    >({
      query: (params) => ({
        url: `/WorkFlows/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    deleteWorkFlow: builder.mutation<string, string>({
      query: (params) => ({
        url: `/WorkFlows/${params}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases", "Sectors"],
    }),

    deleteWorkFlowParameter: builder.mutation<string, string>({
      query: (id) => ({
        url: `/workFlowParameters/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases"],
    }),

    deleteCase: builder.mutation<string, string>({
      query: (id) => ({
        url: `/caseTypes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases"],
    }),

    deleteSector: builder.mutation<string, string>({
      query: (id) => ({
        url: `/sectors/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases", "Sectors"],
    }),
    createSalesWorkFlow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/salesWorkFlows",
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    deleteSalesWorkFlow: builder.mutation<string, string>({
      query: (params) => ({
        url: `/salesWorkFlows/${params}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases"],
    }),
    updateSalesWorkFlow: builder.mutation<
      string,
      CommandRequest<Partial<IWorkFlow>>
    >({
      query: (params) => ({
        url: `/salesWorkFlows/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    createSalesWorkFlowParameter: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/salesWorkFlowParameters",
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    updateSalesWorkflowParameter: builder.mutation<
      string,
      CommandRequest<IWorkFlowParameter>
    >({
      query: (params) => ({
        url: `/salesWorkFlowParameters/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),

    deleteSalesWorkFlowParameter: builder.mutation<string, string>({
      query: (id) => ({
        url: `/salesWorkFlowParameters/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases"],
    }),
    updateSalesWorkOrderNo: builder.mutation<
      string,
      CommandRequest<{ id: string; orderNo: number }[]>
    >({
      query: (params) => ({
        url: `/UpdateSalesWorkFlowOrderNo`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    updateSalesParameterOrderNo: builder.mutation<
      string,
      CommandRequest<{ id: string; orderNo: number }[]>
    >({
      query: (params) => ({
        url: `/UpdateSalesParameterOrderNo`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Cases"],
    }),
    copySalesWorkflow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/salesWorkFlows/CopySalesWorkFlowsBetweenWorkFlows`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
    copyWorkflow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/workFlows/CopyWorkFlowsBetweenWorkFlows`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
    duplicateWorkflow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/workFlows/DuplicateWorkFlows`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
    duplicateWorkflowParameter: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/workFlowParameters/DuplicateWorkFlowParemeters`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
    duplicateSalesWorkflow: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/salesWorkFlows/DuplicateSalesWorkFlows`,
        method: "POST",
        params: { ...params.data },
      }),
      invalidatesTags: ["Cases"],
    }),
  }),
});

export const {
  useCreateWorkFlowMutation,
  useCreateWorkFlowParameterMutation,
  useUpdateWorkFlowMutation,
  useDeleteWorkFlowParameterMutation,
  useDeleteCaseMutation,
  useDeleteSectorMutation,
  useDeleteWorkFlowMutation,
  useCreateSalesWorkFlowMutation,
  useUpdateSalesWorkFlowMutation,
  useDeleteSalesWorkFlowMutation,
  useCreateSalesWorkFlowParameterMutation,
  useUpdateSalesWorkflowParameterMutation,
  useDeleteSalesWorkFlowParameterMutation,
  useUpdateSalesWorkOrderNoMutation,
  useUpdateSalesParameterOrderNoMutation,
  useCopyWorkflowMutation,
  useCopySalesWorkflowMutation,
  useDuplicateWorkflowMutation,
  useDuplicateSalesWorkflowMutation,
  useDuplicateWorkflowParameterMutation
} = userApi;
