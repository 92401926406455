import { appApi } from "@/store/app-api";
import { TaskStatusCount } from "../models/taskStatusCount";
import { QueryRequest } from "@/model/query-request";
import { ClientPerType } from "../models/clientPerType";
import { SectorPerfomance } from "../models/sectorPerformance";
import { ClientSummary } from "../models/clientSummary";

export const organizationDashboardApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskStatusCount: builder.query<TaskStatusCount[], QueryRequest>({
      query: (params) => ({
        url: `/dashboard/GetTaskStatusCounts`,
        method: "GET",
        params: params.data
      })
    }),
    getClientsPerType: builder.query<ClientPerType[], QueryRequest>({
      query: (params) => ({
        url: `/dashboard/GetClientsPerType`,
        method: "GET",
        params: params.data
      })
    }),
    getSectorPerfomance: builder.query<SectorPerfomance[], QueryRequest>({
      query: (params) => ({
        url: `/dashboard/GetSectorPerformance`,
        method: "GET",
        params: params.data
      })
    }),
    getClientSummary: builder.query<ClientSummary, QueryRequest>({
      query: (params) => ({
        url: `/dashboard/GetClientSummary`,
        method: "GET",
        params: params.data
      })
    })
  }),
});

export const {
  useGetTaskStatusCountQuery,
  useGetClientsPerTypeQuery,
  useGetSectorPerfomanceQuery,
  useGetClientSummaryQuery
} = organizationDashboardApi;