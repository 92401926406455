import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { Expense } from "../model/expense";

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    GetAllExpense: builder.query<Expense[], QueryRequest>({
      query: (params) => ({
        url: `/expenses`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Expense"],
    }),
    GetVoidedExpense: builder.query<Expense[], QueryRequest>({
      query: (params) => ({
        url: `/expenses/GetVoidedExpenses`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Expense"],
    }),
    addExpense: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/expenses`,
        method: "POST",
        data: params.data,
      }),
      invalidatesTags: ["Expense"],
    }),
    voidExpense: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/expenses/VoidExpense`,
        method: "PUT",
        params: {...params.data},
      }),
      invalidatesTags: ["Expense"],
    }),
    PayExpense: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/expenses/PayPayment`,
        method: "PUT",
        params: {...params.data},
      }),
      invalidatesTags: ["Expense"],
    }),
  }),
});

export const {
  useGetAllExpenseQuery,
  useAddExpenseMutation,
  useVoidExpenseMutation,
  usePayExpenseMutation,
  useGetVoidedExpenseQuery
} = taskApi;
