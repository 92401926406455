import PageTitle from "@/components/ui/PageTitle";
import { Route } from "react-router-dom";
import { TaskList } from "./page/TaskList";
import { TaskDetailPage } from "./page/TaskDetailPage";
import GenericTask from "../genericTask/pages/GenericTask";
import { GenericTaskDetailPage } from "../genericTask/pages/GenericTaskDetailPage";
import Dashboard  from "../dashboard/organization/pages/Dashboard";
export default (
  <Route path="task">
    <Route 
    index
    element={
      <>
        <PageTitle title="Dashboard"/>
        <Dashboard/>
      </>
    } 
    >

    </Route>
    <Route path="operational-task">
      <Route
        index
        element={
          <>
            <PageTitle title="Operational Task" />
            <TaskList />
          </>
        }
      />

      <Route
        path=":id"
        element={
          <>
            <PageTitle title="Operational Task Detail" />
            <TaskDetailPage />
          </>
        }
      />
    </Route>
    <Route path="generic-task">
      <Route
        index
        element={
          <>
            <PageTitle title="Generic Task" />
            <GenericTask />
          </>
        }
      />
      <Route
        path=":id"
        element={
          <>
            <PageTitle title="Generic Task Detail" />
            <GenericTaskDetailPage />
          </>
        }
      />
    </Route>
  </Route>
);
