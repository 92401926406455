import { useEffect, useState } from "react";
import { CalendarIcon, CircleDashed } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import GenericChart from "@/features/dashboard/organization/components/GenericChart";
import DashboardCard from "@/features/dashboard/client/components/DashboarCard";
import {
  useGetPotentialCustomerReportPerPipelineWorkflowQuery,
  useGetPotentialCustomerReportTenderWorkflowQuery,
  useGetSalesDashboardReportQuery,
} from "../api/sales-dashboard-api";

const SalesDashboard = () => {
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();

  const {
    data: salesDashboardReport,
    isLoading,
    isError,
    refetch,
  } = useGetSalesDashboardReportQuery({
    data: {
      startDate: fromDate,
      endDate: toDate,
    },
  });

  const { data: tenderReport, refetch: refetchTenderReport } =
    useGetPotentialCustomerReportTenderWorkflowQuery({
      data: {
        startDate: fromDate,
        endDate: toDate,
      },
    });

  const { data: leadReportList, refetch: refetchLeadReport } =
    useGetPotentialCustomerReportPerPipelineWorkflowQuery({
      data: {
        startDate: fromDate,
        endDate: toDate,
      },
    });

  useEffect(() => {
    if (toDate || fromDate) {
      refetch();
      refetchTenderReport();
      refetchLeadReport();
    }
  }, [fromDate, toDate]);

  // Prepare data for the chart showing metrics
  const metricsData = [
    { metric: "Total Leads", value: salesDashboardReport?.totalLeads ?? 0 },
    {
      metric: "Total Customers",
      value: salesDashboardReport?.totalCustomers ?? 0,
    },
    {
      metric: "Conversion Rate",
      value: salesDashboardReport?.conversionRate ?? 0,
    },
    {
      metric: "Total Completed Tasks",
      value: salesDashboardReport?.totalCompletedTasks ?? 0,
    },
    {
      metric: "Avg Completion Time",
      value: salesDashboardReport?.averageCompletionTime ?? 0,
    },
  ];

  // Prepare data for the chart showing tasks by status
  const tasksByStatusData =
    salesDashboardReport?.tasksByStatus.map((task) => ({
      status: task.status,
      count: task.count,
    })) ?? [];

  return (
    <div className="py-4 px-2 md:px-6 2xl:px-11 flex flex-col gap-4">
      <div className="flex justify-end gap-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] justify-start text-left font-normal",
                !fromDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {fromDate ? format(fromDate, "PPP") : <span>From date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={fromDate}
              onSelect={setFromDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] justify-start text-left font-normal",
                !toDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {toDate ? format(toDate, "PPP") : <span>To date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={toDate}
              onSelect={setToDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>

      {/* Pipeline Section */}
      <div className="p-4 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">Pipeline</h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {leadReportList?.map((workflow) => (
            <DashboardCard
              key={workflow.workFlowName}
              title={workflow.workFlowName}
              count={workflow.potentialCustomerCount}
              icon={<CircleDashed />}
              link="/pipeline/details"
              linkText="View Details"
              bgColor="white"
              hideAction={true}
            />
          ))}
        </div>
      </div>

      {/* Tender Section */}
      {tenderReport && tenderReport.length > 0 && (
        <div className="p-4 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Tender</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {tenderReport?.map((workflow) => (
              <DashboardCard
                key={workflow.workFlowName}
                title={workflow.workFlowName}
                count={workflow.potentialCustomerCount}
                icon={<CircleDashed />}
                link="/tender/details"
                linkText="View Details"
                bgColor="white"
                hideAction={true}
              />
            ))}
          </div>
        </div>
      )}

      {/* Charts for other reports */}
      <div className="bg-white grid grid-cols-1 md:grid-cols-2 gap-4 p-4 mt-6">
        {/* Chart for overall metrics */}
        <div className="col-span-1">
          <GenericChart
            key={JSON.stringify(metricsData)}
            title="Sales Metrics Overview"
            data={metricsData}
            isLoading={isLoading}
            isError={isError}
            defaultChartType="pie"
            id="sales-metrics-overview"
          />
        </div>

        {/* Chart for tasks by status */}
        <div className="col-span-1">
          <GenericChart
            key={JSON.stringify(tasksByStatusData)}
            title="Tasks by Status"
            data={tasksByStatusData}
            isLoading={isLoading}
            isError={isError}
            defaultChartType="pie"
            id="tasks-by-status"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard;
