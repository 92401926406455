// src/components/VoidPayment.js
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import React, { SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { useVoidPayementMutation } from "../api/payment-collection-api";
import { PaymentCollection } from "../model/PaymentCollection";

// Define Zod schema for form validation
const voidPaymentSchema = z.object({
    remark: z.string().nonempty("Remark is required"),
});

type VoidPaymentFormValues = z.infer<typeof voidPaymentSchema>;

interface VoidPaymentProps {
    openForm: boolean;
    setOpenForm: React.Dispatch<SetStateAction<boolean>>;
    payment: PaymentCollection
}

const VoidPayment = ({ openForm, setOpenForm, payment }: VoidPaymentProps) => {
    const [voidPayment, { isLoading, isSuccess }] = useVoidPayementMutation(); // Hook for voiding payment
    const form = useForm<VoidPaymentFormValues>({
        resolver: zodResolver(voidPaymentSchema),
    });

    useEffect(() => {
        if (isSuccess) {
            form.reset();
            setOpenForm(false);
            Notify("success", "Payment voided successfully!");
        }
    }, [isSuccess]);

    const onSubmit = async (data: VoidPaymentFormValues) => {
        await voidPayment({data:{ id: payment.id, remark: data.remark} }); // Call the mutation to void the payment
    };

    return (
        <DefaultDialog
            title="Void Payment"
            open={openForm}
            onClose={() => setOpenForm(false)}
        >
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                {/* Remark Field */}
                <div className="flex flex-col gap-3">
                    <label htmlFor="remark">Remark</label>
                    <Input
                        id="remark"
                        {...form.register("remark")}
                        placeholder="Enter remark"
                    />
                    {form.formState.errors.remark && (
                        <span className="text-red-500">{form.formState.errors.remark.message}</span>
                    )}
                </div>

                {/* Submit Button */}
                <CustomButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    type="submit"
                    className="w-full"
                >
                    Void Payment
                </CustomButton>
            </form>
        </DefaultDialog>
    );
};

export default VoidPayment;