import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Switch } from "@/components/ui/switch";
import {
  useCreateWorkFlowMutation,
  useUpdateWorkFlowMutation,
} from "../../api/work-flow-command";
import { useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { NotificationOptions } from "@/model/enums";
import { IWorkFlow } from "../../Case/models/workflow";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useGetActiveWorkFlowQuery } from "../../api/work-flow-api";
import { Checkbox } from "@/components/ui/checkbox";
import FormMultiSelect from "@/components/form/FormMultiSelect";

const workFlowFormSchema = z
  .object({
    workFlowName: z.string(),
    caseTypeId: z.string(),
    finalStatusName: z.string(),
    notifyToClient: z.boolean(),
    workFlowStep: z.number(),
    notificationOption: z.array(z.nativeEnum(NotificationOptions)).optional(),
    // notificationOption: z.nativeEnum(NotificationOptions).optional(),
    // hasPayment: z.boolean().default(false),
    // paymentAmount: z.number(),
    isActive: z.boolean().default(true),
    canEditAtAnyStage: z.boolean().default(false),
    isEditByPermission: z.boolean().default(false),
    isFixed: z.boolean().default(false),
    copyFromAnotherWorkflow: z.boolean().optional(), // Optional checkbox field
    sourceWorkflow: z.string().optional(), // This field will be conditionally required
  })
  .refine(
    (data) => !data.copyFromAnotherWorkflow || !!data.sourceWorkflow, // Conditional validation: sourceCaseType required if copyFromAnotherCase is true
    {
      path: ["sourceWorkflow"],
      message:
        "Source Workflow is required when copying from another Workflow.",
    }
  );

type WorkFlowFormValues = z.infer<typeof workFlowFormSchema>;

type AddCaseDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  caseTypeId: string;
  currentNumberOfWorkFlows: number;
  isEditing?: boolean;
  workflow?: IWorkFlow;
};

export const AddWorkFlowDialog = ({
  openDialog,
  setOpenDialog,
  caseTypeId,
  currentNumberOfWorkFlows,
  isEditing,
  workflow,
}: AddCaseDialogProps) => {
  const form = useForm<WorkFlowFormValues>({
    resolver: zodResolver(workFlowFormSchema),
    mode: "onChange",
    defaultValues: {
      caseTypeId,
      workFlowName: workflow?.workFlowName ?? "",
      finalStatusName: workflow?.finalStatusName ?? "",
      workFlowStep: workflow?.workFlowStep ?? currentNumberOfWorkFlows + 1,
      notifyToClient: workflow?.notifyToClient ?? false,
      notificationOption:
        (workflow?.notificationOption as NotificationOptions[]) ?? [],
      // hasPayment: workflow?.hasPayment ?? false,
      // paymentAmount: workflow?.paymentAmount ?? 1,
      isActive: workflow?.isActive ?? true,
      canEditAtAnyStage: workflow?.canEditAtAnyStage ?? false,
      isEditByPermission: workflow?.isEditByPermission ?? false,
      isFixed: workflow?.isFixed ?? false,
    },
  });
  console.log(workflow);

  const [
    createWorkflow,
    {
      isSuccess: isCreatingWorkFlowSuccess,
      isLoading: isCreatingWorkflowLoading,
    },
  ] = useCreateWorkFlowMutation();

  const { data: activeWorkflows = [] } = useGetActiveWorkFlowQuery({});

  const [
    updateWorkflow,
    {
      isSuccess: isUpdatingWorkFlowSuccess,
      isLoading: isUpdatingWorkflowLoading,
    },
  ] = useUpdateWorkFlowMutation();

  const onSubmit = async () => {
    if (isEditing && workflow?.id) {
      await updateWorkflow({
        data: { id: workflow.id, ...form.getValues() },
      });
      return;
    } else {
      const { ...otherValues } = form.getValues();
      const data = {
        ...otherValues,
        sourceWorkFlowId: form.getValues("sourceWorkflow"),
      };
      await createWorkflow({
        data: { ...data },
      });
    }
  };
  useEffect(() => {
    if (isCreatingWorkFlowSuccess || isUpdatingWorkFlowSuccess) {
      setOpenDialog(false);
      form.reset();
    }
  }, [
    form,
    isCreatingWorkFlowSuccess,
    isUpdatingWorkFlowSuccess,
    setOpenDialog,
  ]);
  useEffect(() => {
    form.reset();
  }, [form, openDialog]);
  return (
    <DefaultDialog
      title={isEditing ? "Edit Workflow Step" : "Add Workflow Step"}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 p-4 z-999999"
        >
          <FormField
            control={form.control}
            name="workFlowName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Workflow Step Name</FormLabel>
                <FormControl>
                  <Input placeholder="Workflow Step Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="finalStatusName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Final Status Name</FormLabel>
                <FormControl>
                  <Input placeholder="Final Status Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notifyToClient"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Notify User</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {form.getValues("notifyToClient") && (
            <FormMultiSelect
              form={form}
              control={form.control}
              formLabel="Notification Options"
              name="notificationOption"
              options={Object.entries(NotificationOptions)
                .filter(([key]) => key !== "None" && key !== "All")
                .map(([key]) => ({
                  label: key,
                  value:
                    NotificationOptions[
                      key as keyof typeof NotificationOptions
                    ],
                }))}
              defaultValue={workflow?.notificationOption}
            />
          )}

          {/* {form.getValues("hasPayment") && (
            <>
              <FormField
                control={form.control}
                name="paymentAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Payment Amount</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Payment Amount"
                        {...field}
                        type="number"
                        value={field.value || ""} // Ensure the value is not undefined
                        onChange={(event) => {
                          const value = event.target.value;
                          const parsedValue = value === "" ? "" : Number(value);
                          field.onChange(parsedValue);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isFixed"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Is Fixed Amount
                      </FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </>
          )} */}
          {isEditing && (
            <FormField
              control={form.control}
              name="isActive"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Is Active</FormLabel>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="canEditAtAnyStage"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">
                    Can Edit At Any Stage
                  </FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isEditByPermission"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">
                    Edit By Permission
                  </FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {!isEditing && (
            <FormField
              control={form.control}
              name="copyFromAnotherWorkflow"
              render={({ field }) => (
                <FormItem className="">
                  <div className="flex items-center space-x-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Copy from another WorkFlow</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {/* Show source case dropdown if the checkbox is checked */}
          {!isEditing && form.watch("copyFromAnotherWorkflow") && (
            <FormField
              control={form.control}
              name="sourceWorkflow"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source Workflow</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        form.setValue("sourceWorkflow", value);
                      }}
                      value={field.value ?? ""}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Source Workflow" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {activeWorkflows.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.workFlowName}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <div className="flex items-center">
            <CustomButton
              type="submit"
              className="w-50 mx-auto"
              isLoading={isUpdatingWorkflowLoading || isCreatingWorkflowLoading}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
