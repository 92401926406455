import { IUser } from "@/features/users/models/User";
import { NotificationOptions } from "@/model/enums";

export interface IAlert {
  id: string;
  message: string;
  time: string;
  taskId: string;
  dueDate: string;
  remainingDays: string;
  taskReference: string;
  alertFrom: string;
  alertTypeName: string;
  alertTypeId: string;
  isComplted: boolean;
  daysBeforeAlert: number;
  users?: IUser[];
  snoozeUnit: string;
  snoozeDuration: number;
  alertTitle: string;
}

export interface IAlertType {
  id: string;
  alertName: string;
  remark: string;
  notificationOption: NotificationOptions[];
  organizationId: string;
}

export enum SnoozeUnit {
  None = "None",
  Minutes = "Minutes",
  Hours = "Hours",
  Days = "Days",
}
