import { Button } from "@/components/ui/button";
import { Check, ChevronDown } from "lucide-react";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect, useState } from "react";
import { useGetSectorsQuery, useUpdateCaseMutation } from "../api/case-api";
import { ICaseWithSector } from "../models/Case";
import { Switch } from "@/components/ui/switch";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";

const caseFormSchema = z.object({
  id: z.string(),
  caseName: z.string({
    required_error: "Please enter a case name.",
  }),
  caseDescription: z.string(),
  sectorId: z.string().nonempty("Sector is required"),
  standardDays: z.preprocess(
    (val) => Number(val),
    z.number().min(1, { message: "Must be at least 1 day." })
  ),

  // z.string().transform((v) => Number(v) || 1),
  isActive: z.boolean().default(true),
});
type CaseFormValues = z.infer<typeof caseFormSchema>;

type AddCaseDialogProps = {
  open: boolean;
  onClose: (open: boolean) => void;
  selectedCase: ICaseWithSector;
};

export const UpdateCaseDialog = ({
  open,
  onClose,
  selectedCase,
}: AddCaseDialogProps) => {
  const form = useForm<CaseFormValues>({
    resolver: zodResolver(caseFormSchema),
    mode: "onChange",
    defaultValues: {
      id: selectedCase.id,
      caseName: selectedCase.type,
      caseDescription: selectedCase.caseDescription,
      sectorId: selectedCase.sectorId ?? "",
      standardDays: selectedCase.standardDays,
      isActive: selectedCase.isActive,
    },
  });
  const [
    updateCase,
    { isSuccess: isUpdatingSectorSuccess, isLoading: isUpdatingCaseLoading },
  ] = useUpdateCaseMutation();

  const onSubmit = async () => {
    await updateCase({
     data: {
      id: selectedCase.id,
      caseDescription: form.getValues().caseDescription,
      type: form.getValues().caseName,
      sectorId: form.getValues().sectorId,
      standardDays: form.getValues().standardDays,
      isActive: form.getValues().isActive
     }
    });
  };
  useEffect(() => {
    if (isUpdatingSectorSuccess) {
      onClose(false);
      form.reset();
      Notify("success", "Case Updated Successfully!")
    }
  }, [form, isUpdatingSectorSuccess, onClose]);

  const [openSector, setOpenSector] = useState<boolean>(false);
  const { data: sectors = [] } = useGetSectorsQuery({
    url: "/sectors",
    method: "GET",
  });

  return (
    <DefaultDialog open={open} title="Edit Case" onClose={onClose}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="grid gap-2 ">
            <div className="">
              <FormField
                control={form.control}
                name="sectorId"
                render={({ field }) => (
                  <FormItem className="flex flex-col w-full">
                    <FormLabel>Sector<span className="text-red-500 ml-1">*</span></FormLabel>
                    <Popover open={openSector} onOpenChange={setOpenSector}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value
                              ? sectors.find(
                                  (sector) => sector.id === field.value
                                )?.sectorName
                              : "Select Sector"}
                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-100 p-0 z-9999">
                        <Command>
                          <CommandInput placeholder="Search Sector..." />
                          <CommandList>
                            <CommandEmpty>No Sector found.</CommandEmpty>
                            <CommandGroup>
                              {sectors.map((sector) => (
                                <CommandItem
                                  value={sector.id}
                                  key={sector.id}
                                  onSelect={() => {
                                    form.setValue("sectorId", sector.id);
                                    setOpenSector(false);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      sector.id === field.value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {sector.sectorName}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <FormField
            control={form.control}
            name="caseName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Case Name<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Case Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="standardDays"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Standard Day<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Standard Day" {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="caseDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="description"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isActive"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Is Active</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="flex items-center">
            <CustomButton
              type="submit"
              className="w-50 mx-auto"
              isLoading={isUpdatingCaseLoading}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
