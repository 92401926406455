import { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"; // Import Sales Dashboard
import SalesDashboard from "@/features/sales/dashboard/pages/SalesDashboard";
import TaskDashboard from "@/features/task/page/TaskDashboard";
import FinanceDashboard from "@/features/finance/Dashboard/pages/FinanceDashboard";

const OrganizationDashboard = () => {
  const [activeTab, setActiveTab] = useState("task"); // Keep track of the active tab

  return (
    <div className="py-4 px-2 md:px-6 2xl:px-11 flex flex-col gap-4">
      {/* Tab Navigation using shadcn */}
      <Tabs defaultValue={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="task">Task</TabsTrigger>
          <TabsTrigger value="finance">Finance</TabsTrigger>
          <TabsTrigger value="sales">Sales</TabsTrigger>
        </TabsList>

        <TabsContent value="task">
          <TaskDashboard /> {/* Render Task Dashboard */}
        </TabsContent>

        <TabsContent value="finance">
          <FinanceDashboard />
        </TabsContent>

        <TabsContent value="sales">
          <SalesDashboard /> {/* Render Sales Dashboard */}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default OrganizationDashboard;
