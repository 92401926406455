import React, { SetStateAction, useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "@/components/shared/PhoneInput/phone-input";
import { Notify } from "@/components/shared/Notification/notify";
import { useEditCompanyProfileMutation } from "../api/company-api";
import { IOrganization } from "@/features/organizations/model/Organization";
import { Textarea } from "@/components/ui/textarea";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";

const companyFormSchema = z.object({
  name: z.string().nonempty("Company name is required"),
  address: z.string().nonempty("Address is required"),
  email: z
    .string()
    .email("Invalid email address")
    .nonempty("Email is required"),
  phoneNumber: z
    .string()
    .nonempty("Phone number is required")
    .refine(isValidPhoneNumber, {
      message: "Invalid phone number",
    }),
  country: z.string().nonempty("Country is required"),
  city: z.string().nonempty("City is required"),
  mission: z.string().nonempty("Mission is required"),
  vision: z.string().nonempty("Vision is required"),
  values: z.string().nonempty("Values are required"),
});

type CompanyFormValues = z.infer<typeof companyFormSchema>;

type EditCompanyProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  company: IOrganization;
};

const EditCompanyProfile = ({
  openForm,
  setOpenForm,
  company,
}: EditCompanyProps) => {
  const [editCompany, { isLoading, isSuccess }] =
    useEditCompanyProfileMutation();

  const defaultValues: CompanyFormValues = {
    name: company.name,
    address: company.address,
    email: company.email,
    phoneNumber: company.phoneNumber,
    country: company.country,
    city: company.city,
    mission: company.mission,
    vision: company.vision,
    values: company.values,
  };

  const form = useForm<CompanyFormValues>({
    resolver: zodResolver(companyFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: CompanyFormValues) => {
    await editCompany({
      data: {
        id: company.id,
        name: data.name,
        address: data.address,
        email: data.email,
        phoneNumber: data.phoneNumber,
        country: data.country,
        city: data.city,
        mission: data.mission,
        vision: data.vision,
        values: data.values,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "Company Profile Updated Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog
      open={openForm}
      title="Edit Company Profile"
      onClose={() => setOpenForm(false)}
      maxWidth="xl"
    >
      <Form {...form}>
        <form
          className="flex flex-col gap-4 px-3"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Company Name<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Company Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Email<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Phone Number<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <PhoneInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Country<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Country" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Address<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Address" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>City<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="City" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="mission"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Mission<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Textarea placeholder="Mission" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vision"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Vision<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Textarea placeholder="Vision" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="values"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Values<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Textarea placeholder="Values" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <CustomButton
            disabled={isLoading || !form.formState.isValid}
            isLoading={isLoading}
            className="lg:self-center"
            type="submit"
          >
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditCompanyProfile;
