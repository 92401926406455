import { useEffect } from "react";
import { Switch } from "@/components/ui/switch";
import { useGetAllPermissionsQuery, useGetPermissionsByRoleIdQuery, useOnOffPermissionsMutation } from "../api/user-api";

interface RoleDetailProps {
    roleId: string;
}

const PermissionsTab = ({ roleId }: RoleDetailProps) => {
  const {data: permissionList} = useGetAllPermissionsQuery()
  const {data: rolePermissions=[], refetch} = useGetPermissionsByRoleIdQuery({data:{id:roleId}})
  const [OnoffPermission] = useOnOffPermissionsMutation()

  const handleTogglePermission = async (permissionId: string, state: boolean) => {
    await OnoffPermission({
        data: {
            roleId: roleId,
            permissionId: permissionId,
            isOn: state
        }
    })
  }

  useEffect(() => {
    refetch()
  }, [roleId])
  
  return (
    <div className="flex flex-col gap-4 w-full">
        {permissionList?.map((group) => ( // Assuming permissionList is an array of PermissionsWithGroup
            <div key={group.moduleName} className="flex flex-col gap-2 w-full">
                <h3>{group.moduleName}</h3>
                {group.permissions.map((permission) => (
                    <div key={permission.id} className="w-full flex justify-between items-center border-2 rounded-lg p-4">
                        <p className="font-normal text-md">{permission.permissionName}</p>
                        <Switch
                           checked={rolePermissions.some(rolePermission => rolePermission.id === permission.id)} // Check if permission is in rolePermissions
                           onCheckedChange={(checked) => {
                               handleTogglePermission(permission.id, checked); // Cast to HTMLInputElement
                           }} 
                        />
                    </div>
                ))}
            </div>
        ))}
    </div>
  );
};

export default PermissionsTab;