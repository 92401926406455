import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IGenericTask } from "../model/GenericTask";
import { useSearchGenericsQuery } from "../api/generic-task-api";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";

const taskListColumns: MRT_ColumnDef<IGenericTask>[] = [
  {
    accessorKey: "taskReference",
    header: "Reference Number",
  },
  {
    accessorKey: "taskName",
    header: "Task Name",
  },
  {
    accessorKey: "startDate",
    header: "StartDate",
    Cell: ({ row }) => {
      return new Date(row.original.startDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "taskStatus",
    header: "Status",
  },
  {
    accessorKey: "completionRate",
    header: "Progress",
    Cell: ({ row }) => {
      return (
        <>
          <CircularPercentageIndicator value={row.original.completionRate} />
        </>
      );
    },
  },
];

type Props = {
  status:
    | "ongoing"
    | "Started"
    | "Finished"
    | "Submitted"
    | "Canceled"
    | "Suspended";
};

export const GenericTaskListByStatus = ({ status }: Props) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const [globalFilter, setGlobalFilter] = useState("");

  const { data: tasks, isLoading } = useSearchGenericsQuery({
    data: {
      taskStatus: status,
      orderDirection: "desc",
    },
  });

  return (
    <MaterialReactTable
      columns={taskListColumns}
      data={tasks?.list ?? []}
      enablePagination
      manualPagination
      rowCount={tasks?.count ?? 0} // Total number of records (if server returns it)
      state={{ pagination, density: "compact", globalFilter, isLoading }}
      onPaginationChange={setPagination}
      initialState={{
        pagination: { pageIndex: 1, pageSize: 50 },
      }}
      // enableColumnFilters={false}
      manualFiltering
      onGlobalFilterChange={setGlobalFilter} //hoist internal global state to your state
      muiTableBodyRowProps={(row) => ({
        onClick: () => {
          navigate(`/task/generic-task/${row.row.original.id}`);
        },
        sx: {
          cursor: "pointer",
        },
      })}
    />
  );
};
