import React, { SetStateAction, useEffect } from 'react';
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from '@/components/shared/Button/CustomButton';
import { useEditUserInfoMutation } from '../api/user-api';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { IUser } from '../models/User';
import { PhoneInput } from '@/components/shared/PhoneInput/phone-input';
import { Notify } from '@/components/shared/Notification/notify';
import { FullScreenDialog } from '@/components/shared/DefaultDailog/FullPageDialog';

const userFormSchema = z.object({
    fullName: z.string().nonempty("Full Name is required"),
    userName: z.string().nonempty("User Name is required"),
    email: z
        .string()
        .email("Invalid email address")
        .nonempty("Email is required"),
    phoneNumber: z
        .string()
        .nonempty("Phone number is required")
        .refine(isValidPhoneNumber, {
            message: "Invalid phone number",
        })
})

type UserFormValues = z.infer<typeof userFormSchema>;

type EditUserProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  user: IUser
};

const EditUser = ({ openForm, setOpenForm, user }: EditUserProps) => {
  const [editUser, { isLoading, isSuccess }] = useEditUserInfoMutation();

  const defaultValues: UserFormValues = {
    fullName: user.fullName,
    userName: user.username,
    email: user.email,
    phoneNumber: user.phoneNumber
  };

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: UserFormValues) => {
    await editUser({
      url: `/users/${user.id}/UpdateUserInfo`,
      method: "PUT",
      data: {
        id: user.id,
        fullName: data.fullName,
        email: data.email,
        username: data.userName,
        PhoneNumber: data.phoneNumber,
        roleIds: ["fe6a7e79-4ab1-4489-b3ec-e6086708c8a1"]
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "User Edited Successfully!")
    }
  }, [isSuccess, setOpenForm]);

  return (
    <FullScreenDialog open={openForm} title="Edit User"  onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="flex flex-col gap-4 px-3" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                    <FormItem className='flex-1'>
                        <FormLabel>Full Name<span className="text-red-500 ml-1">*</span></FormLabel>
                        <FormControl>
                            <Input placeholder="Full Name" {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="userName"
                render={({ field }) => (
                    <FormItem className='flex-1'>
                        <FormLabel>User Name<span className="text-red-500 ml-1">*</span></FormLabel>
                        <FormControl>
                            <Input {...field} placeholder="User Name"/>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                    <FormItem className='flex-1'>
                        <FormLabel>Email<span className="text-red-500 ml-1">*</span></FormLabel>
                        <FormControl>
                            <Input type='email' {...field} placeholder="Email"/>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                    <FormItem className='flex-1'>
                        <FormLabel>Phone Number<span className="text-red-500 ml-1">*</span></FormLabel>
                        <FormControl>
                            <PhoneInput {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <CustomButton disabled={isLoading || !form.formState.isValid} isLoading={isLoading} className="lg:self-center" type="submit">
                SUBMIT
            </CustomButton>
        </form>
      </Form>
    </FullScreenDialog>
  );
};

export default EditUser;