import { Button } from "@/components/ui/button";
import { CalendarIcon, Check, ChevronDown, Plus } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect, useRef, useState } from "react";
import { useGetCasesQuery } from "@/features/workflows/Case/api/case-api";
import { useLazyGetCustomersQuery } from "@/features/customers/api/customer-api";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { useCreateTaskMutation } from "../api/task-command";
import AddCustomer from "@/features/customers/pages/AddCustomer";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Notify } from "@/components/shared/Notification/notify";
import { useGetUsersQuery } from "@/features/users/api/user-api";
import ReactSelect from "react-select"; // Importing react-select as an alias
import { IUser } from "@/features/users/models/User";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import FormCheckboxWithSwitch from "@/components/form/FormCheckboxWithSwitch";

type AddCaseDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  // updateCase: (newCase: ICreateCase) => void;
};

export const AddTaskDialog = ({
  openDialog,
  setOpenDialog,
}: AddCaseDialogProps) => {
  const { session } = useAuth();
  const caseFormSchema = z
    .object({
      clientId: z.string().optional(),
      caseTypeId: z.string({
        required_error: "Please select a case.",
      }),

      startDate: z.date({
        required_error: "A Start Date is required.",
      }),
      endDate: z
        .date({
          required_error: "A End Date is required.",
        })
        .optional(),
      assignedUsersId: z
        .array(z.string())
        .min(1, "At least one user is required"),
      taskName: z.string().nonempty("Task Name Required!"),
      taskDescription: z.string().optional(),
      ForInternalUse: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
      // Date Validation
      if (data.endDate && data.startDate >= data.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "End Date must be after Start Date.",
          path: ["endDate"],
        });
      }
      const isUserTypeClient = session?.userInfo.userType === UserType.Client;
      const isForInternalUse = data.ForInternalUse === true;

      // Conditional Requirement for clientId
      const shouldRequireClientId = !isUserTypeClient && !isForInternalUse;

      if (shouldRequireClientId) {
        if (!data.clientId || data.clientId.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a customer.",
            path: ["clientId"],
          });
        }
      }
    });

  type CaseFormValues = z.infer<typeof caseFormSchema>;

  const form = useForm<CaseFormValues>({
    resolver: zodResolver(caseFormSchema),
    mode: "onChange",
    defaultValues: {
      assignedUsersId: [],
      ForInternalUse: false,
    },
  });

  const [openSector, setOpenSector] = useState<boolean>(false);
  const [openCaseDialog, setOpenCaseDialog] = useState<boolean>(false);
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);

  const [getCustomers, { data: customerList }] = useLazyGetCustomersQuery();
  const { data: usersList } = useGetUsersQuery({
    data: {
      userType: UserType.Staff,
    },
  });

  const { data: caseList = [] } = useGetCasesQuery();

  const [
    createTask,
    { isSuccess: isCreatingTaskSuccess, isLoading: isCreatingTaskLoading },
  ] = useCreateTaskMutation();

  const onSubmit = async () => {
    await createTask({
      data:
        session?.userInfo.userType === UserType.Client
          ? {
              clientId: session.userInfo.id,
              caseTypeId: form.getValues().caseTypeId,
              startDate: form.getValues().startDate,
              endDate: form.getValues().endDate,
              assignedUsersId: [],
              taskName: form.getValues().taskName,
              taskDescription: form.getValues().taskDescription,
              ForInternalUse: form.getValues().ForInternalUse,
            }
          : {
              clientId: form.getValues().clientId,
              caseTypeId: form.getValues().caseTypeId,
              startDate: form.getValues().startDate,
              endDate: form.getValues().endDate,
              assignedUsersId: form.getValues().assignedUsersId,
              taskName: form.getValues().taskName,
              taskDescription: form.getValues().taskDescription,
              ForInternalUse: form.getValues().ForInternalUse,
            },
    });
  };

  useEffect(() => {
    if (isCreatingTaskSuccess) {
      setOpenDialog(false);
      form.reset();
      Notify(
        "success",
        session?.userInfo.userType === UserType.Client
          ? "Task Request Sent Successfully"
          : "Task Added Successfully"
      );
    }
  }, [form, isCreatingTaskSuccess, session?.userInfo.userType, setOpenDialog]);

  useEffect(() => {
    getCustomers({
      url: "/clients/SearchClients",
      method: "GET",
      data: {
        organizationId: session?.userInfo.organizationId,
        pageNumber: 1,
        pageSize: 100000,
        clientName: "",
      },
    });
  }, [getCustomers, session?.userInfo.organizationId]);
  const clientIdRef = useRef(null);
  const caseTypeIdRef = useRef<HTMLButtonElement>(null);
  const startDateRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <DefaultDialog
        open={openDialog}
        title={
          session?.userInfo.userType === UserType.Client
            ? "Request Task"
            : "Add Task"
        }
        onClose={() => setOpenDialog(false)}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-4"
          >
            {session?.userInfo.userType !== UserType.Client &&
              !form.getValues("ForInternalUse") && (
                <div>
                  <FormLabel>
                    Customer<span className="text-red-500 ml-1">*</span>
                  </FormLabel>
                  <div className="grid gap-2 grid-cols-3">
                    <div className="col-span-2">
                      <Controller
                        name="clientId"
                        control={form.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="w-full">
                            <Popover
                              open={openSector}
                              onOpenChange={setOpenSector}
                            >
                              <PopoverTrigger asChild className="w-full">
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  className={cn(
                                    "justify-between",
                                    !field.value && "text-muted-foreground"
                                  )}
                                  ref={clientIdRef}
                                >
                                  {field.value
                                    ? customerList?.list?.find(
                                        (customer) =>
                                          customer.id === field.value
                                      )?.clientName
                                    : "Select Customer"}
                                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-full p-0 z-9999">
                                <Command>
                                  <CommandInput
                                    placeholder="Search Customer"
                                    onInput={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const query = event.target.value;
                                      getCustomers({
                                        url: "/clients/SearchClients",
                                        method: "GET",
                                        data: {
                                          organizationId:
                                            session?.userInfo.organizationId,
                                          pageNumber: 1,
                                          pageSize: 100000,
                                          clientName: query,
                                        },
                                      });
                                    }}
                                  />
                                  <CommandList>
                                    <CommandEmpty>
                                      No Customer found.
                                    </CommandEmpty>
                                    <CommandGroup>
                                      {customerList?.list?.map((customer) => (
                                        <CommandItem
                                          value={customer.id}
                                          key={customer.id}
                                          onSelect={() => {
                                            form.setValue(
                                              "clientId",
                                              customer.id
                                            );
                                            setOpenSector(false);
                                            caseTypeIdRef.current?.focus();
                                          }}
                                        >
                                          <Check
                                            className={cn(
                                              "mr-2 h-4 w-4",
                                              customer.id === field.value
                                                ? "opacity-100"
                                                : "opacity-0"
                                            )}
                                          />
                                          {customer.clientName}
                                        </CommandItem>
                                      ))}
                                    </CommandGroup>
                                  </CommandList>
                                </Command>
                              </PopoverContent>
                            </Popover>
                            {error && (
                              <p className="text-red-600 text-sm mt-1">
                                {error.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <Button
                      className=""
                      onClick={() => setOpenAddCustomer(true)}
                    >
                      <Plus size={18} />
                      New Customer
                    </Button>
                  </div>
                </div>
              )}
            <FormCheckboxWithSwitch
              control={form.control}
              name="ForInternalUse"
              label="Is For Internal"
            />
            <FormField
              control={form.control}
              name="caseTypeId"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>
                    Select Case<span className="text-red-500 ml-1">*</span>
                  </FormLabel>
                  <Popover
                    open={openCaseDialog}
                    onOpenChange={setOpenCaseDialog}
                  >
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            !field.value && "text-muted-foreground"
                          )}
                          ref={caseTypeIdRef}
                        >
                          {field.value
                            ? caseList.find(
                                (customer) => customer.id === field.value
                              )?.type
                            : "Select Case"}
                          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-100 p-0 z-9999">
                      <Command className="">
                        <CommandInput placeholder="Search Customer" />
                        <CommandList>
                          <CommandEmpty>No Customer found.</CommandEmpty>
                          <CommandGroup>
                            {caseList.map((c) => (
                              <CommandItem
                                value={c.caseDescription}
                                key={c.id}
                                onSelect={() => {
                                  form.setValue("caseTypeId", c.id ?? "");
                                  setOpenCaseDialog(false);
                                  startDateRef.current?.focus();
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    c.id === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />

                                {c.type}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>
                    Start date<span className="text-red-500 ml-1">*</span>
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                          ref={startDateRef}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 z-9999" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={(value) => {
                          field.onChange(value);
                          endDateRef.current?.focus();
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>End Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                          ref={endDateRef}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 z-9999" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={(value) => {
                          field.onChange(value);
                          submitButtonRef.current?.focus();
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="taskName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Task Name<span className="text-red-500 ml-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter Task Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="taskDescription"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Task Description</FormLabel>
                  <FormControl>
                    <Textarea {...field} placeholder="Enter Task Description" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="assignedUsersId"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>
                    Assigned User<span className="text-red-500 ml-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <ReactSelect
                      isMulti
                      options={usersList?.map((item: IUser) => ({
                        label: item.fullName,
                        value: item.id,
                      }))}
                      value={field.value.map((id) => ({
                        label:
                          usersList?.find((item: IUser) => item.id === id)
                            ?.fullName || id,
                        value: id,
                      }))}
                      onChange={(selected) =>
                        field.onChange(selected.map((s) => s.value))
                      }
                      placeholder="Select User"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex items-center">
              <Button
                type="submit"
                className="w-50 mx-auto"
                // isLoading={isCreatingTaskLoading}
                ref={submitButtonRef}
                disabled={isCreatingTaskLoading}
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DefaultDialog>
      {openAddCustomer && (
        <AddCustomer
          openForm={openAddCustomer}
          setOpenForm={setOpenAddCustomer}
        />
      )}
    </>
  );
};
