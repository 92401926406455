import React, { useState } from "react";
import { IWorkFlowWithParameters } from "@/features/workflows/Case/models/workflow";
import { AlertFrom, ITaskDetail } from "@/features/task/models/Task";
import {
  Box,
  Grid2,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuth } from "@/lib/auth";
import { Button } from "@/components/ui/button";
import { HelpCircleIcon, MoveRight, TriangleAlert } from "lucide-react";
import { CloseMoveToNextDialog } from "@/features/task/components/CloseMoveToNextDialog";
import { AlertDialogComponent } from "@/features/task/components/AlertDialogComponent";
import DynamicForm from "@/features/task/components/DaynamicForm";
import { UserType } from "@/model/SideBarMenu";

type Props = {
  taskDetail: ITaskDetail;
};

export const SalesWorkflowStepper = ({ taskDetail }: Props) => {
  const [selectedWf, setSelectedWf] =
    React.useState<IWorkFlowWithParameters | null>(null);

  const allWorkFlows = React.useMemo(
    () => [...taskDetail.workFlow, ...taskDetail.nextSteps],
    [taskDetail.workFlow, taskDetail.nextSteps]
  );

  React.useEffect(() => {
    setSelectedWf(
      allWorkFlows.find((wf) => wf.id === taskDetail.currentWorkFlow.id) ??
        allWorkFlows[0]
    );
  }, [allWorkFlows, taskDetail.currentWorkFlow.id]);

  const { session } = useAuth();
  const canEdit = () => {
    if (!selectedWf) return false;
    const isClient = session?.userInfo.userType === UserType.Client;
    const statuses = ["Finished", "Cancelled", "Suspended"];
    const isUserPermittedToEdit = selectedWf.isCurrentUserEditPermitted;

    if (selectedWf.isEditByPermission && !isUserPermittedToEdit) {
      return false;
    }
    if (statuses.includes(taskDetail.task.taskStatus)) {
      return false;
    }
    if (isClient) {
      return false;
    }
    if (selectedWf.id === taskDetail.currentWorkFlow.id) {
      return true;
    }

    // return selectedWf.canEditAtAnyStage;
  };
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openMoveToNextStepDialog, setOpenMoveToNextStepDialog] =
    useState<boolean>(false);
  const handleStep = (wf: IWorkFlowWithParameters) => () => {
    setSelectedWf(wf);
  };

  return (
    selectedWf && (
      <Box>
        <Grid2 container>
          <Grid2 size={3} bgcolor={"#fff"} p={2}>
            <Stepper
              orientation="vertical"
              activeStep={allWorkFlows.findIndex(
                (wf) => wf.id === selectedWf?.id
              )}
              nonLinear
            >
              {allWorkFlows
                .slice()
                .sort((a, b) => a.workFlowStep - b.workFlowStep)
                .map((wf) => (
                  <Step
                    key={wf.id}
                    completed={wf.isCompleted}
                    active={selectedWf?.id === wf.id}
                  >
                    <StepButton color="inherit" onClick={handleStep(wf)}>
                      {wf.workFlowName}
                    </StepButton>
                  </Step>
                ))}
            </Stepper>
          </Grid2>
          <Grid2 size={9} bgcolor={"#fff"} p={2}>
            {selectedWf && (
              <>
                <Box>
                  <Typography>{selectedWf?.workFlowName}</Typography>
                  <DynamicForm
                    key={selectedWf.id}
                    taskId={taskDetail.task.id}
                    workFlowId={selectedWf.id}
                    parameters={selectedWf.workFlowParameter}
                    currentWorkFlow={taskDetail.currentWorkFlow}
                    taskStatus={taskDetail.task.taskStatus}
                    workflow={selectedWf}
                    isSalesOperation
                  />

                  {canEdit() && (
                    <>
                      <div className="flex mt-5 gap-2 justify-between">
                        <Button
                          size="sm"
                          variant="defaultPrimary"
                          className="flex gap-2"
                          onClick={() => setOpenAlertDialog(true)}
                        >
                          <TriangleAlert size={18} />
                          Add Alert
                        </Button>
                        <div className="flex gap-2">
                          <div className="flex justify-center items-center">
                            <Tooltip title="To proceed to the next stage or to close, all mandatory (*) fields must be filled.">
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>

                          <Button
                            size={"sm"}
                            onClick={() => setOpenMoveToNextStepDialog(true)}
                            // disabled={
                            //   !validateRequiredParameters(
                            //     selectedWf.workFlowParameter
                            //   )
                            // }
                            className={
                              taskDetail.task.taskStatus === "BackToRevision"
                                ? "hidden"
                                : ""
                            }
                          >
                            {taskDetail.nextSteps.length === 0 ? (
                              "Close"
                            ) : (
                              <>
                                Move To Next
                                <MoveRight size={16} className="ml-2" />
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                      <CloseMoveToNextDialog
                        openDialog={openMoveToNextStepDialog}
                        onClose={() => setOpenMoveToNextStepDialog(false)}
                        taskId={taskDetail.task.id}
                        title={
                          taskDetail.nextSteps.length === 0
                            ? "Close Workflow"
                            : "Move to Next Step"
                        }
                        isSalesWf
                      />
                      <AlertDialogComponent
                        taskId={taskDetail.task.id}
                        workflowId={selectedWf.id}
                        openAlertDialog={openAlertDialog}
                        setOpenAlertDialog={() => {
                          setOpenAlertDialog(false);
                        }}
                        isSalesWf
                        alertFrom={
                          taskDetail.caseType.name === "Tender Management"
                            ? AlertFrom.TenderSalesTask
                            : AlertFrom.PipelineSalesTask
                        }
                      />
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid2>
        </Grid2>
      </Box>
    )
  );
};
