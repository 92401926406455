import { Button } from "@/components/ui/button";
import { Edit2, EyeIcon } from "lucide-react"; // Icon library for the edit button
import { ICustomer } from "../models/Customer";
import { useState } from "react";
import { ClientInfo } from "./ClientInfo";
import { Separator } from "@radix-ui/react-dropdown-menu";

interface Props {
  client: ICustomer;
  onEditClick?: () => void; // Optional prop for handling edit click
  showEditButton?: boolean; // New prop to toggle edit button visibility
  showTaskCount?: boolean; // New prop to toggle task count visibility
}

export const ClientCard = ({
  client,
  onEditClick,
  showEditButton = false,
  showTaskCount = false,
}: Props) => {
  const [openViewInfo, setOpenViewInfo] = useState<boolean>(false);

  return (
    <>
      <div className="bg-white my-2 py-4 flex flex-col gap-4 shadow-sm rounded-lg">
        <div className="flex justify-between items-center px-2 md:px-8">
          {/* Client Information */}
          <div className="flex flex-col">
            <p className="text-xl font-semibold">{client?.clientName}</p>
            <div className="flex gap-4">
              <div className="flex gap-2 items-center">
                <p>Client Type</p>
                <p className="bg-secondary px-2 rounded-full">
                  {client?.clientType}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <p>Status</p>
                <p className="bg-secondary px-2 rounded-full">
                  {client?.isActive ? "Active" : "InActive"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setOpenViewInfo(true)}
              variant={"outline"}
              className="border-2"
            >
              <EyeIcon /> View Detail
            </Button>
            {/* Conditionally render Edit Button */}
            {showEditButton && (
              <Button
                onClick={onEditClick}
                variant={"outline"}
                className="text-primary border-primary border-2"
              >
                <Edit2 /> EDIT
              </Button>
            )}
          </div>
        </div>

        {/* Responsive Info Section */}
        <div className="w-full flex flex-col gap-2 lg:flex-row lg:gap-8 px-2 md:px-8">
          <div className="flex flex-col">
            <p className="text-sm font-light">Credit</p>
            <p className="text-lg font-medium">{`${client?.creditUsedSoFar}/${client?.creditLimit}`}</p>
            <Separator className="my-2 lg:hidden border" />
          </div>
          {/* <div className="flex flex-col">
            <p className="text-sm font-light">Credit Used</p>
            <p className="text-lg font-medium">{client?.creditUsedSoFar}</p>
            <Separator className="my-2 lg:hidden border" />
          </div> */}
          <div className="flex flex-col">
            <p className="text-sm font-light">Total Payment Due</p>
            <p className="text-lg font-medium">
              {new Intl.NumberFormat("en-ET", {
                style: "currency",
                currency: "ETB",
              }).format(client?.totalPaymentDue || 0)}
            </p>
            <Separator className="my-2 lg:hidden border" />
          </div>
        </div>
        <div className="w-full px-2 md:px-8">
        <Separator className="w-full border" />
        </div>
        {showTaskCount && client.taskStatusCount && (
          <div className="w-full px-2 md:px-8">
            {/* <p className="text-lg font-semibold mb-2">Task Count</p> */}
            <div className="flex flex-col gap-4 lg:flex-row">
              {client.taskStatusCount.map((task, index) => (
                <div
                  key={index}
                  className="flex flex-col"
                >
                  <p className="text-sm font-light">{task.status}</p>
                  <p className="text-lg font-medium">{task.count}</p>
                  <Separator className="my-2 lg:hidden border" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {openViewInfo && (
        <ClientInfo
          client={client}
          openViewInfo={openViewInfo}
          setOpenViewInfo={setOpenViewInfo}
        />
      )}
    </>
  );
};
