import { appApi } from "@/store/app-api";
import { CommandRequest } from "@/model/command-request";
import {
  IAlertNotification,
  ITaskNoteCreate,
} from "@/features/task/models/Task";

export interface ITaskDetail {
  workFlowId: string;
  workFlowParameterId: string;
  value: string | File;
}

export interface ITaskUpdateDetailsAndStatus {
  taskId: string;
  taskDetails: ITaskDetail[];
}

export interface ITaskMoveToNextWorkflowStep {
  taskId: string;
  note: string;
}
export interface CancelSuspendData {
  taskId: string;
  note: string;
}
interface BackWorkflowData {
  taskId: string;
  note: string;
}
interface ReviseWorkflowData {
  taskId: string;
  note: string;
}

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    updateSalesTaskDetailsAndStatus: builder.mutation<
      string,
      CommandRequest<ITaskUpdateDetailsAndStatus>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/salesTasks/UpdateSalesTaskDetailsAndStatus`,
        data: params.data,
        params: {
          taskId: params.data.taskId,
        },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    salesMoveToNextStep: builder.mutation<
      string,
      CommandRequest<ITaskMoveToNextWorkflowStep>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/salesTasks/SalesMoveToNextStep`,
        params: { taskId: params.data.taskId },
        data: { note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),

    cancelSalesTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: "/salesTasks/CancelSalesTask",
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    BackWorkflow: builder.mutation<string, CommandRequest<BackWorkflowData>>({
      query: (params) => ({
        method: "PUT",
        url: "/otasks/BackTask",
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    ReviseWorkflow: builder.mutation<
      string,
      CommandRequest<ReviseWorkflowData>
    >({
      query: (params) => ({
        method: "PUT",
        url: "/otasks/ReviseTask",
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    suspendSalesTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/salesTasks/SuspendSalesTask`,
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),

    activateSalesTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/salesTasks/ActivateSalesTask`,
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    saveTaskNote: builder.mutation<string, CommandRequest<ITaskNoteCreate>>({
      query: (params) => ({
        method: "POST",
        url: `/notes`,
        data: { ...params.data },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    addAlertFortTask: builder.mutation<
      string,
      CommandRequest<Partial<IAlertNotification>>
    >({
      query: (params) => ({
        method: "POST",
        url: `/alert-notifications`,
        data: { ...params.data },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "Alerts"],
    }),
  }),
});

export const {
  useUpdateSalesTaskDetailsAndStatusMutation,
  useSalesMoveToNextStepMutation,
  useCancelSalesTaskMutation,
  useBackWorkflowMutation,
  useReviseWorkflowMutation,
  useSuspendSalesTaskMutation,
  useActivateSalesTaskMutation,
  useSaveTaskNoteMutation,
  useAddAlertFortTaskMutation,
} = taskApi;
