import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect } from "react";
import { useAddRoleMemberMutation, useGetUsersQuery } from "../api/user-api";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import CustomButton from "@/components/shared/Button/CustomButton";
import Select, { MultiValue } from "react-select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { IUser } from "../models/User";

type Props = {
  open: boolean;
  onClose: () => void;
  roleId: string;
  members: string[];
};

const schema = z.object({
  selectedUsers: z.array(z.string()).nonempty("At least one user must be selected"),
});

type Option = {
  label: string;
  value: string;
};

export const AddMemberDialog = ({
  roleId,
  open,
  onClose,
  members,
}: Props) => {
  const { session } = useAuth();
  const { data: usersList = [] } = useGetUsersQuery({
    data: {
      organizationId: session?.userInfo.organizationId,
      userType: UserType.Staff,
    },
  });
  const [addRoleMember, { isLoading, isSuccess }] = useAddRoleMemberMutation();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      selectedUsers: [],
    },
  });

  const onSubmit = async (data: { selectedUsers: string[] }) => {
    await addRoleMember({
      data: {
        roleId,
        userId: data.selectedUsers,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      form.reset()
    }
  }, [isSuccess]);

  // Filter out members from the users list
  const options: Option[] = usersList
    .filter((user: IUser) => !members.includes(user.id))
    .map((user: IUser) => ({
      label: user.fullName,
      value: user.id,
    }));

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title="Add Member"
      maxWidth="xs"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="selectedUsers"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Users<span className="text-red-500 ml-1">*</span></FormLabel>
                <FormControl>
                  <Select<Option, true>
                    isMulti
                    options={options}
                    value={field.value.map((id) => {
                      const option = options.find((item) => item.value === id);
                      return { label: option?.label || id, value: id };
                    })}
                    onChange={(selected: MultiValue<Option>) =>
                      field.onChange(selected.map((s) => s.value))
                    }
                    placeholder="Select Users"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mt-2">
            <CustomButton className="w-full" isLoading={isLoading} type="submit">
              Add
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
